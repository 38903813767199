import React, { useState, createContext } from 'react';
import * as queryString from 'query-string';
import Layout from 'src/layouts';
import Query from 'src/components/GraphQL/components/Query';
import gql from 'graphql-tag';
import { MapPin, Info } from 'react-feather';
import DropdownFilterForm from 'src/components/Forms/components/DropdownFilterForm';
import propertyFilterByAmenity from 'src/util/propertyFilterByAmenity';
import get from 'lodash/get';
import LoadingSpinner from 'src/components/LoadingSpinner';
import QuoteGenerator from 'src/components/QuoteGenerator';
import CheckAvailabilityForm from './components/CheckAvailabilityForm';
import VrboSearchForm from './components/VrboSearchForm';
import ChipsList from './components/ChipsList';
import Modal from 'src/components/Modal';
import YouTube from 'src/images/youtube.svg';
import MatterPort from 'src/images/matterport.svg';
import OverviewTable from './components/OverviewTable';
import Grid from 'src/images/grid.svg';
import GridOn from 'src/images/grid-on.svg';
import Details from 'src/images/details.svg';
import DetailsOn from 'src/images/details-on.svg';
import FloorPlan from 'src/images/floor-plan.svg';
import { updateUrlWithCommaDelimitedString } from 'src/util/urlHelper';
import { formDataToArray } from 'src/util/formDataHelper';
import './styles.scss';
import earlyPayout from '../../images/early-payout.svg';

const PropertySelectContext = createContext();

const PROPERTIES = gql`
  query Properties(
    $checkIn: Date
    $checkOut: Date
    $flexibilityInDays: Int # $key_in: [String]
  ) {
    propertyAmenities {
      key
      name
    }
    properties(
      where: {
        # key_in: $key_in
        calendar: {
          availability: {
            checkIn: $checkIn
            checkOut: $checkOut
            flexibilityInDays: $flexibilityInDays
          }
        }
      }
    ) {
      id
      name
      key
      label
      sleeps
      sleepsInBed
      bedrooms
      bathrooms
      type
      description
      addressDisplay
      group
      maxAdults
      maxAdultComfort
      bedroomsPrivate
      bedroomsSemiPrivate
      bathroomsFull
      bathroomsHalf
      sqft
      parkingCovered
      parkingUncovered
      amenities {
        name
        key
        value
      }
      sitePage {
        status
        featuredImage {
          alt
          file {
            version(where: { key: "small" }) {
              fullUrl
            }
          }
        }
        slug
        featuredFiles {
          file {
            fullUrl
            mimetype
          }
        }

        featuredImage {
          file {
            medium: version(where: { key: "medium" }) {
              fullUrl
            }
          }
        }

        featuredFileCollection {
          files {
            versions {
              fullUrl
            }
          }
        }
      }
      virtualTours {
        url
      }
      # propertyGroup {
      #   name
      #   key
      # }
      addressDisplay
      latitude
      longitude
      streetAddress1
      propertyListings {
        platform {
          name
          key
        }
        propertyUrl
      }

      ... on Property {
        mistingSystem {
          id
          label
          description
        }

        floorPlan {
          id
          fileInstance {
            id
            caption
            destination
            file {
              fullUrl
            }
          }
        }
      }
      masterPlatformListing {
        payoutType
      }
      ... on PropertyCombo {
          masterPlatformListing {
            payoutType
          }
     
        childProperties {
          id
          key
          label
        }
      }
    }
    propertyGroups {
      label: name
      key
    }
  }
`;

const propertyListingLabelMapper = {
  Airbnb: 'AirBNB',
  'Booking.com': 'Booking',
  TripAdvisor: 'TripAdvisor',
  HomeAway: 'HomeAway',
};

const areFiltersApplied = queryParams => {
  const foundFilter = Object.keys(queryParams).find(param => {
    if (
      param === 'amenities' ||
      param === 'checkIn' ||
      param === 'checkOut' ||
      param === 'flexibilityInDays'
    ) {
      return param;
    }
  });
  return foundFilter ? true : false;
};

const Properties = ({ location, navigate }) => {
  const queryParams = queryString.parse(location.search);

  const [filtersApplied, setFiltersApplied] = useState(
    areFiltersApplied(queryParams)
  );
  const [filteredProperties, setFilteredProperties] = useState(null);
  const [availabilityModalIsOpen, setAvailabilityModalIsOpen] = useState(false);
  const [vrboSearchModalIsOpen, setVrboSearchModalIsOpen] = useState(false);
  const [isQuoteGeneratorModalOpen, setIsQuoteGeneratorModalOpen] = useState(
    false
  );
  const [targetProperty, setTargetProperty] = useState(null);
  const [view, setView] = useState(queryParams.view || 'grid');

  return (
    <Layout metaTitle={'Properties'}>
      <Query
        query={PROPERTIES}
        variables={{
          checkIn: queryParams.checkIn,
          checkOut: queryParams.checkOut,
          flexibilityInDays: Number(queryParams.flexibilityInDays),
          key_in: queryParams.property || null,
        }}
      >
        {({ loading, error, data, refetch: refetchFilteredProperties }) => {
          if (loading)
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '80vh' }}
              >
                <LoadingSpinner />
              </div>
            );
          const getFilteredProperties = properties => {
            let activeProperties = properties.filter(
              property => property.sitePage.status == 'PUBLISHED'
            );
            if (queryParams.property) {
              const found = activeProperties.find(
                property => property.key === queryParams.property
              );

              return found ? [found] : activeProperties;
            }
            if (queryParams.group) {
              const found = activeProperties.filter(
                property => property.group === queryParams.group
              );

              return found ? found : activeProperties;
            }
            return activeProperties;
          };

          const propertiesWithoutCombos =
            (get(data, 'properties') || []).filter(
              property => get(property, 'type') !== 'COMBO'
            ) || [];

          if (error) return <p>{error.message}</p>;

          const { propertyGroups, propertyAmenities: possibleAmenities } = data;

          let propertyList = filteredProperties
            ? filteredProperties
            : getFilteredProperties(propertiesWithoutCombos || []);

          const hasAllAmenities = (property, amenities = []) => {
            if (amenities.length) {
              const propertyAmenities = property.amenities.map(
                amenity => amenity.key
              );
              const foundAmenities = amenities.reduce((acc, amenity) => {
                if (propertyAmenities.includes(amenity)) {
                  acc += 1;
                }
                return acc;
              }, 0);
              const ret = foundAmenities === amenities.length;
              return ret;
            }
            return true;
          };
          const bedrooms = get(queryParams, 'bedrooms') || 0,
            bathrooms = get(queryParams, 'baths') || 0,
            sleeps = get(queryParams, 'sleeps') || 0,
            amenities = get(queryParams, 'amenities')
              ? get(queryParams, 'amenities').split(',')
              : [];

          const properties = propertyList.filter(property => {
            const ret1 =
              property.bedrooms >= Number(bedrooms) &&
              property.bathrooms >= Number(bathrooms) &&
              property.sleeps >= Number(sleeps) &&
              hasAllAmenities(property, amenities);
            return ret1;
          });

          return (
            <div className="flex-column h-100 d-flex px-3 __container-fluid properties __mb-4">
              <div id="filter-wrapper">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-between py-4">
                    <div className="d-flex align-items-center">
                      <h1 className="mb-0 mr-2">Property Overview</h1>
                      <DropdownFilterForm
                        propertyGroups={propertyGroups}
                        properties={properties}
                        propertyDropdownOptions={
                          properties &&
                          properties.reduce((acc, property) => {
                            const fullUrl =
                              get(
                                property,
                                'sitePage.featuredImage.file.medium.fullUrl'
                              ) || null;
                            if (property.type !== 'COMBO' && fullUrl) {
                              acc.push({
                                label: `${property.label} - ${property.name}`,
                                key: property.key,
                              });
                            }
                            return acc;
                          }, [])
                        }
                        onPropertyFilterSelect={filter => {
                          const allProperties = properties || [];
                          const found =
                            allProperties.find(
                              property => property.label === filter
                            ) || {};
                          setFilteredProperties(null);
                          setFiltersApplied(true);
                          refetchFilteredProperties();
                        }}
                        onGroupFilterSelect={filter => {
                          const allProperties = properties || [];
                          const filteredPropertiesByGroup = allProperties.filter(
                            property => property.group === filter
                          );
                          setFilteredProperties(null);
                          setFiltersApplied(true);
                          refetchFilteredProperties();
                        }}
                        filtersApplied={false}
                        groupDropdownOptions={propertyGroups}
                        onClearFilters={() => {
                          setFilteredProperties(null);
                          setFiltersApplied(false);
                          navigate(`/properties`);
                        }}
                        onFormSubmit={formFilters => {
                          const dropdownFilterForm = document.querySelector(
                            '#dropdown-filter-form'
                          );
                          const arrayWithSwimSpaToPlungePool = formDataToArray(
                            dropdownFilterForm
                          ).reduce((acc, amenity) => {
                            if (amenity === 'swimSpa') {
                              acc.push('plungePool');
                            } else {
                              acc.push(amenity);
                            }
                            return acc;
                          }, []);

                          updateUrlWithCommaDelimitedString(
                            'properties',
                            arrayWithSwimSpaToPlungePool.join(','),
                            'amenities'
                          );
                          refetchFilteredProperties();
                          const {
                            updatedPropertiesByAmenityFilter,
                          } = propertyFilterByAmenity(
                            propertiesWithoutCombos || [],
                            formFilters
                          );

                          setFilteredProperties(
                            updatedPropertiesByAmenityFilter
                          );
                          setFiltersApplied(true);
                        }}
                      />
                      <button
                        data-toggle="modal"
                        onClick={() => setAvailabilityModalIsOpen(true)}
                        className="btn btn-outline-primary mx-2"
                      >
                        <span className={`d-none d-md-inline`}>Check</span>{' '}
                        Availability
                      </button>
                      <button
                        data-toggle="modal"
                        onClick={() => setVrboSearchModalIsOpen(true)}
                        className="btn btn-outline-primary mx-2 d-none d-md-inline-block"
                      >
                        Generate Vrbo Search Link
                      </button>
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        {view === 'details' && (
                          <button
                            onClick={() => {
                              navigate(`/properties?view=grid`);
                              setView('grid');
                            }}
                            className="btn btn-link p-0"
                          >
                            <img width="20" src={Grid} />
                          </button>
                        )}
                        {view === 'grid' && (
                          <button className="btn btn-link p-0">
                            <img width="20" src={GridOn} />
                          </button>
                        )}
                      </div>
                      <div>
                        {view === 'grid' && (
                          <button
                            onClick={() => {
                              navigate(`/properties?view=details`);
                              setView('details');
                            }}
                            className="btn btn-link p-0 ml-2"
                          >
                            <img width="20" src={Details} />
                          </button>
                        )}
                        {view === 'details' && (
                          <button className="btn btn-link p-0 ml-2">
                            <img width="20" src={DetailsOn} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!loading && (
                <div id="chips-list-wrapper">
                  <ChipsList
                    onUpdate={(formFilters, clearAll) => {
                      if (!formFilters || clearAll) {
                        setFiltersApplied(queryParams.amenities ? true : false);
                        setFilteredProperties(null);
                        refetchFilteredProperties();
                      } else {
                        const {
                          updatedPropertiesByAmenityFilter,
                        } = propertyFilterByAmenity(
                          propertiesWithoutCombos || [],
                          formFilters
                        );
                        setFilteredProperties(updatedPropertiesByAmenityFilter);
                        const checkInOrCheckOutApplied =
                          queryParams.checkIn || queryParams.checkOut;
                        setFiltersApplied(
                          Object.keys(formFilters).length > 0
                            ? true
                            : checkInOrCheckOutApplied
                        );
                      }
                    }}
                    filteredProperties={properties}
                    loading={loading}
                    location={location}
                    amenities={possibleAmenities}
                  />
                </div>
              )}
              {loading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '80vh' }}
                >
                  <LoadingSpinner />
                </div>
              )}
              {!loading && view === 'grid' && (
                <div className="col">
                  <div className="row">
                    {properties.reduce((acc, property) => {
                      const matterPortLink =
                        get(property, 'virtualTours[0].url') || null;
                      const featuredFiles =
                        get(property, 'sitePage.featuredFiles') || [];
                      const youTubeFile = featuredFiles.find(
                        featuredFile =>
                          (featuredFile.file || {}).mimetype === 'video/youtube'
                      );
                      const youTubeLink = youTubeFile
                        ? youTubeFile.file.fullUrl
                        : null;
                      const fullUrl =
                        get(
                          property,
                          'sitePage.featuredImage.file.medium.fullUrl'
                        ) || null;

                      const foundChannel =
                        (property.propertyListings &&
                          property.propertyListings.find(
                            channel => channel.platform.key === 'homeaway'
                          )) ||
                        {};
                      const homeAwayUrl = foundChannel.propertyUrl
                        ? foundChannel.propertyUrl
                        : null;
                      const platformLinks =
                        (property.propertyListings &&
                          property.propertyListings.map(property => {
                            return {
                              label: property.platform.name,
                              url: property.propertyUrl,
                            };
                          })) ||
                        [];
                      if (property.type !== 'COMBO') {
                        acc.push(
                          <div
                            key={`column - ${property.id}`}
                            className="col col-12 col-lg-6 col-xl-4 mb-4"
                          >
                            <div className={'card property-card'}>
                              <img
                                className="card-img-top"
                                src={`${
                                  fullUrl
                                    ? fullUrl
                                    : 'https://via.placeholder.com/428x321'
                                }`}
                                alt="Card image cap"
                              />
                              <div className="card-body pt-3 pl-3 pr-3 pb-0">
                                <div className="card-title mb-0 d-flex justify-content-between">
                                  <span className="font-weight-bold">
                                    {property.label} - {property.name}
                                    {' '} {property.masterPlatformListing?.payoutType == 'EARLY' && <><img title="Early Payout" src={earlyPayout} className={`mr-1`} /></>}
                                  </span>
                                  <div>
                                    {youTubeLink && (
                                      <a
                                        className="mr-1"
                                        target="_blank"
                                        title="YouTube"
                                        href={youTubeLink}
                                      >
                                        <img width="20" src={YouTube} />
                                      </a>
                                    )}
                                    {matterPortLink && (
                                      <a
                                        target="_blank"
                                        title="3D Tour"
                                        href={matterPortLink}
                                      >
                                        <img width="20" src={MatterPort} />
                                      </a>
                                    )}
                                    {property.floorPlan?.fileInstance && (
                                      <a
                                        target="_blank"
                                        href={`${property.floorPlan.fileInstance.file.fullUrl}`}
                                      >
                                        <img
                                          className="mx-1plus"
                                          src={FloorPlan}
                                        />
                                      </a>
                                    )}
                                  </div>
                                </div>
                                {property.addressDisplay && (
                                  <div className="card-subtitle mt-1">
                                    {property.addressDisplay
                                      ? property.addressDisplay
                                      : ''}
                                  </div>
                                )}
                                <a
                                  href={homeAwayUrl}
                                  className={` ${
                                    homeAwayUrl ? '' : 'disabled'
                                  } btn btn-outline-primary w-100 mt-3 mb-0`}
                                >
                                  Book Now on HomeAway
                                </a>
                                <ul className="p-0 mb-0 row">
                                  {platformLinks.map(link => {
                                    if (link.label === 'HomeAway') {
                                      return (
                                        <li
                                          key={link.label}
                                          className={
                                            'col col-4 platform-links d-inline-block ml-0 mr-0 mb-2 pr-0'
                                          }
                                        >
                                          <a
                                            target="_blank"
                                            href={link.url ? link.url : ''}
                                          >
                                            {link.label}
                                          </a>
                                        </li>
                                      );
                                    }
                                  })}
                                  {platformLinks.map((link, index) => {
                                    if (link.label !== 'HomeAway') {
                                      return (
                                        <li
                                          key={index}
                                          className={
                                            'platform-links d-inline-block ml-0 mr-0 mb-2 col col-4 pr-0'
                                          }
                                        >
                                          <a
                                            target="_blank"
                                            href={link.url ? link.url : ''}
                                          >
                                            {link.label}
                                          </a>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                              <hr className="mt-0 mb-0 ml-3 mr-3" />
                              <ul className="amenities-list pl-3 pr-3 mb-3 mt-3 p-0 d-flex justify-content-between">
                                <li className="d-inline-block mt-1">
                                  {property.bedrooms} Bedrooms
                                </li>
                                <li className="d-inline-block mt-1">
                                  {property.bathrooms} Baths
                                </li>
                                <li className="d-inline-block mt-1">
                                  {property.sleeps} Beds
                                </li>
                                <li className="d-inline-block mt-1">
                                  {property.maxAdultComfort} Adults Comfort
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      }
                      return acc;
                    }, [])}
                  </div>
                </div>
              )}
              {!loading && view === 'details' && (
                <OverviewTable properties={properties} />
              )}
              <Modal
                styleType="large"
                isOpen={isQuoteGeneratorModalOpen}
                onClose={() => setIsQuoteGeneratorModalOpen(false)}
                disableForceFocus={true}
                title={targetProperty && targetProperty.name}
              >
                <QuoteGenerator
                  propertyId={targetProperty && targetProperty.id}
                />
              </Modal>

              <Modal
                isOpen={availabilityModalIsOpen}
                disableFocusTrap={true}
                title={'Availability'}
                onClose={() => setAvailabilityModalIsOpen(false)}
              >
                <CheckAvailabilityForm
                  onSelect={() => {
                    setFiltersApplied(true);
                  }}
                  loading={loading}
                  results={properties.length}
                  onClose={() => setAvailabilityModalIsOpen(false)}
                />
              </Modal>

              <Modal
                isOpen={vrboSearchModalIsOpen}
                disableFocusTrap={true}
                title={'Generate Vrbo Search Link'}
                onClose={() => setVrboSearchModalIsOpen(false)}
                
              >
                <VrboSearchForm
                  onClose={() => setVrboSearchModalIsOpen(false)}
                />
              </Modal>
            </div>
          );
        }}
      </Query>
    </Layout>
  );
};

const PropertyTypeSelectConsumer = PropertySelectContext.Consumer;
export { PropertyTypeSelectConsumer };

export default Properties;
