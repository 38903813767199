// console.log('useEffect', openTYFRModal);
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'src/components/Modal';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Tooltip from 'src/components/Tooltip';
import tyfrImage from 'src/images/tyfr.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const GET_RESERVATION_DATA = gql`
  query GuestReservation2($reference: String) {
    guestReservation(where: { reference: $reference }) {
      reference
      checkIn
      checkOut
      guest {
        firstName
        lastName
      }
      formEntries {
        id
      }
      reservationFormToken {
        token
      }
      platform {
        name
      }
      property {
        name
      }
    }
  }
`;

export const TYFRLinkModal = ({
  isOpen,
  onClose = new Function(),
  onSuccess,
  reference,
  children,
}) => {
  // const [modalIsOpen, setModalIsOpen] = useState(false);

  const [copied, setCopied] = useState(false);
  const { data, error, loading, refetch, ...rest } = useQuery(
    GET_RESERVATION_DATA,
    {
      variables: { reference: reference },
      fetchPolicy: 'no-cache',
    }
  ); //
  // console.log('loading', loading);
  // console.log('rest', rest);
  // if (loading) {
  //   return <LoadingSpinner />;
  // }
  const {
    checkIn,
    checkOut,
    guest,
    reservationFormToken,
    platform,
    property,
    formEntries = [],
  } = data?.guestReservation || {};

  // console.log('loading', loading);
  // console.log('isOpen', isOpen);
  // console.log('!isOpen', !isOpen);
  // console.log('data', data);
  return (
    <>
      <Modal
        title={`TYFR Form ${guest?.firstName ? `(${guest.firstName} ${guest.lastName})` : ''
          }`}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        styleType="large"
        disableForceFocus={true}
      >
        {loading || !isOpen || !data ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="row">
              <div className="col col-12 col-md-6">
                <div className="form-group">
                  <label>Reference</label>
                  <span className="text-danger ml-1">*</span>
                  <input
                    readOnly={true}
                    name="reference"
                    type="text"
                    className={`form-control`}
                    value={reference}
                  />
                </div>
              </div>

              <div className="col col-12 col-md-6">
                <div className="form-group">
                  <label>Platform</label>
                  <span className="text-danger ml-1">*</span>
                  <input
                    name="platformName"
                    readOnly={true}
                    type="text"
                    className={`form-control`}
                    value={platform.name}
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col col-12 col-md-6">
                <div className="form-group">
                  <label>Property</label>
                  <span className="text-danger ml-1">*</span>
                  <input
                    name="propertyName"
                    readOnly={true}
                    type="text"
                    className={`form-control`}
                    value={property.name}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="form-group d-flex" id="dates-form-group">
                  <div className="mr-3 w-100">
                    <label>Check In</label>
                    <span className="text-danger ml-1">*</span>
                    <input
                      name="checkIn"
                      readOnly={true}
                      type="text"
                      className={`form-control`}
                      value={
                        checkIn ? moment(checkIn).format('MM/DD/YYYY') : ''
                      }
                      placeholder=""
                    />
                  </div>
                  <div className="w-100">
                    <label>Check Out</label>
                    <span className="text-danger ml-1">*</span>
                    <input
                      name="checkOut"
                      readOnly={true}
                      type="text"
                      className={`form-control`}
                      value={
                        checkOut ? moment(checkOut).format('MM/DD/YYYY') : ''
                      }
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {
              <>
                <p>
                  https://www.secureinformationcollection.com/webforms/tyfr?reference=
                  {reference}&token=
                  {encodeURIComponent(reservationFormToken.token)}
                </p>
                {copied && (
                  <div className="w-100 alert alert-success">
                    Copied to Clipboard
                  </div>
                )}
                <CopyToClipboard
                  text={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${reference}&token=${encodeURIComponent(
                    reservationFormToken.token
                  )}`}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2500);
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary d-block w-100"
                  >
                    Click to Copy Form Link
                  </button>
                </CopyToClipboard>
                <a
                  className="btn btn-outline-primary d-block w-100 mt-3"
                  target="_blank"
                  href={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${reference}&token=${encodeURIComponent(
                    reservationFormToken.token
                  )}`}
                >
                  Click to Visit Form
                </a>
                {formEntries.length > 0 && (
                  <>
                    <a
                      className="btn btn-outline-primary d-block w-100 mt-3"
                      target="_blank"
                      href={`https://www.bhipmanagement.com/webforms/${formEntries[0].id}`}
                    >
                      Click to View Completed Form
                    </a>
                  </>
                )}
              </>
            }
          </>
        )}
      </Modal>
    </>
  );
};

const TYFRFormLinkIcon = ({ children, reference, isOpen, tooltipDisabled }) => {
  const [tyfrFormModalIsOpen, setTyfrFormModalIsOpen] = useState(
    isOpen ? true : false
  );
  // useEffect(() => {
  //   console.log('useEffect2', isOpen);
  //   if (isOpen == true) {
  //     setTyfrFormModalIsOpen(true);
  //   }
  // }, [isOpen]);
  return <>
    {
      tooltipDisabled ? <><button
        className="btn btn-link p-0"
        type="button"
        onClick={() => {
          setTyfrFormModalIsOpen(true);
        }}
      // href={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${
      //   original.reference
      // }&token=${encodeURIComponent(original.token)}`}
      >
        {children ? children : <img src={tyfrImage} />}
      </button> </> : <Tooltip
        label="TYFR Form Link"
        id={`tooltip-${reference}`}
        placement="top"
        className="ml-2"
      >
        <button
          className="btn btn-link p-0"
          type="button"
          onClick={() => {
            setTyfrFormModalIsOpen(true);
          }}
        // href={`https://www.secureinformationcollection.com/webforms/tyfr?reference=${
        //   original.reference
        // }&token=${encodeURIComponent(original.token)}`}
        >
          {children ? children : <img src={tyfrImage} />}
        </button>    </Tooltip>
    }
    <>
      {tyfrFormModalIsOpen && (
        <TYFRLinkModal
          isOpen={tyfrFormModalIsOpen}
          reference={reference}
          onClose={() => {
            setTyfrFormModalIsOpen(false);
          }}
        />
      )}
    </>
  </>;
};

export default TYFRFormLinkIcon;
