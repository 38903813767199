import React, { useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import Query from 'src/components/GraphQL/components/Query';
// import { withApollo } from 'react-apollo';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Loadable from '@loadable/component';
import FormResponse from 'src/components/Forms/components/FormResponse';
import LoadingSpinner from 'src/components/LoadingSpinner';
import TYFRFormLinkIcon from 'src/components/TYFRFormLinkIcon';
import { ExternalLink } from 'react-feather';
import FinalizedOptionsForm from 'src/routes/reports/components/BookingsTable/components/FinalizedOptionsForm';
import RedFlagsForm from 'src/routes/reports/components/BookingsTable/components/RedFlagsForm';
import Modal from 'src/components/Modal';
import TYFRFormEntryModal from '../TYFRFormEntryModal';
import './styles.scss';
import { Info } from 'react-feather';
import Tooltip from 'src/components/Tooltip';
import moment from 'moment';
import { enumToLabel } from 'src/util';

import * as Yup from 'yup';
import { update } from 'lodash';
// import { withApollo } from 'node_modules/react-apollo/lib/index';

// import { stubTrue } from 'lodash';

const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));

// const PROPERTIES_QUERY = gql`
//   query Properties {
//     properties {
//       id
//       name
//     }
//   }
// `;
const validationSchema = Yup.object().shape({
  reference: Yup.string().required('Reference is required'),
  platformId: Yup.string().required('Platform is required'),
  propertyId: Yup.string().required('Property is required'),
  checkIn: Yup.string().required('Check in is required'),
  checkOut: Yup.string().required('Check out is required'),
  reservationCreateDate: Yup.string()
    .required('Reservation create date is required')
    .nullable(),
  status: Yup.string().required('Status is required'),
  toddlers: Yup.number().optional().nullable(),
  preteens: Yup.number().optional().nullable(),
  children: Yup
  .number()
  .when("toddlers", {
    is: val =>  Number(val) > 0,
    then: Yup.number().test({
      name: 'toddlers',
      exclusive: false,
      params: { },
      message: 'Ages must equal the amount of children',
      test: function (children) {
          // You can access the price field with `this.parent`.
        //  console.log('children', children)
          return parseFloat(this.parent.preteens) + parseFloat(this.parent.toddlers) == parseFloat(this.parent.children)
      },
    })
  })

});

const CREATE_RESERVATION_QUERY = gql`
  query CreateReservationQuery {
    platforms {
      id
      name
      key
    }

    properties {
      id
      name
      propertyListings {
        id
      }
      # key
    }
    guestReservationStatusOptions {
      type
    }
    resortAccess: __type(name: "GuestReservationResortAccessOption") {
      name
      enumValues {
        name
      }
    }
    midStayClean: __type(name: "GuestReservationMidStayCleanOption") {
      name
      enumValues {
        name
      }
    }
    mobilityChallenged: __type(
      name: "GuestReservationMobilityChallengedOption"
    ) {
      name
      enumValues {
        name
      }
    }
    largeVehicles: __type(name: "GuestReservationLargeVehicleOption") {
      name
      enumValues {
        name
      }
    }
    dateSquatterExempt: __type(name: "GuestReservationDateSquatterExempt") {
      name
      enumValues {
        name
      }
    }
    confirmationStatus: __type(name: "GuestReservationConfirmationStatus") {
      name
      enumValues {
        name
      }
    }
    eventType: __type(name: "GuestReservationEventType") {
      name
      enumValues {
        name
      }
    }
    cancellationTypeOptions: __type(
      name: "GuestReservationCancellationTypeOption"
    ) {
      name
      enumValues {
        name
      }
    }
    agreementSigned: __type(name: "GuestReservationAgreementSigned") {
      name
      enumValues {
        name
      }
    }
  }
`;

const CREATE_GUEST_RESERVATION = gql`
  mutation CreateGuestReservation(
    $guestReservationCreateInput: GuestReservationCreateInput!
  ) {
    createGuestReservation(data: $guestReservationCreateInput) {
      guest {
        id
      }
    }
  }
`;

const UPDATE_GUEST_RESERVATION = gql`
  mutation UpdateGuestReservation(
    $guestWhereUniqueInput: GuestReservationWhereUniqueInput!
    $guestReservationUpdateInput: GuestReservationUpdateInput
  ) {
    updateGuestReservation(
      where: $guestWhereUniqueInput
      data: $guestReservationUpdateInput
    ) {
      guest {
        id
      }
    }
  }
`;

const GUEST_RESERVATION = gql`
  query GuestReservation($reference: String!) {
    guestReservationFinalizationStepOptions {
      id
      label
      stage
      sort
    }
    guestReservationRedFlagOptions {
      id
      # type
      label
      sort
    }
    guestReservationStatusOptions {
      type
    }

    guestReservation(where: { reference: $reference }) {
      id
      reference
      status
      dateSquatterExempt
      vehicles
      largeVehicles
      resortAccess
      midStayClean
      mobilityChallenged
      externalConversationUrl
      cancellationType
      propertyListing {
        id
      }
      property {
        name
        id
        amenities {
          key
        }
      }

      paymentRequests {
        id
        description
        status
        amount
        date
    
        externalPaymentUrl
   
      }

      geoRelatedReservations {
        reference
        externalConversationUrl
        guest {
          id
        }
      }
      receipt {
        VRPTotalTravelerPayment
      }
      platform {
        id
        name
        key
      }
      guest {
        firstName
        lastName
      }
      checkIn
      checkOut
      reservationCreateDate
      visitors
      children
      toddlers
      preteens
      adults
      pets
      agreementSigned
      eventType
      confirmationStatus
      status
      finalizationSteps {
        id
        label
        readOnly
      }
      redFlags {
        id
        label
        type
      }
      formEntries {
        id
        createdAt
        formName
      }
    }
    resortAccess: __type(name: "GuestReservationResortAccessOption") {
      name
      enumValues {
        name
      }
    }
    midStayClean: __type(name: "GuestReservationMidStayCleanOption") {
      name
      enumValues {
        name
      }
    }
    mobilityChallenged: __type(
      name: "GuestReservationMobilityChallengedOption"
    ) {
      name
      enumValues {
        name
      }
    }
    largeVehicles: __type(name: "GuestReservationLargeVehicleOption") {
      name
      enumValues {
        name
      }
    }
    dateSquatterExempt: __type(name: "GuestReservationDateSquatterExempt") {
      name
      enumValues {
        name
      }
    }
    confirmationStatus: __type(name: "GuestReservationConfirmationStatus") {
      name
      enumValues {
        name
      }
    }
    eventType: __type(name: "GuestReservationEventType") {
      name
      enumValues {
        name
      }
    }
    cancellationTypeOptions: __type(
      name: "GuestReservationCancellationTypeOption"
    ) {
      name
      enumValues {
        name
      }
    }
    agreementSigned: __type(name: "GuestReservationAgreementSigned") {
      name
      enumValues {
        name
      }
    }
  }
`;

const PROPERTY_LISTINGS = gql`
  query PropertyListings {
    propertyListings {
      id
      property {
        id
      }
      platform {
        id
      }
    }
  }
`;

const GuestReservationForm = withApollo(({
  client,
  reference,
  onSuccess,
  onDataLoad,
  guestId,
  guestReservationRedFlagOptions,
  ...rest
}) => {
  console.log('client', client)
  const [finalizationStepsState, setFinalizationStepsState] = useState(null);
  const [finalizeStatusModalIsOpen, setFinalizeStatusModalIsOpen] = useState(
    false
  );
  const [redFlagsModalIsOpen, setRedFlagsModalIsOpen] = useState(false);
  const [redFlagsState, setRedFlagsState] = useState(null);
  const [TYFRModalIsOpen, setTYFRModalIsOpen] = useState(false);
  //TYFRFormEntryModal
  return (
    <Query
      // variables={{
      //   reference,
      //   skip: !reference,
      // }}
      query={CREATE_RESERVATION_QUERY}
    >
      {({ loading, error, data: createReservationQueryData, refetch }) => {
        const platforms = createReservationQueryData?.platforms;
        const createFormAgreementOptions =
          createReservationQueryData?.agreementSigned?.enumValues;
        const createFormDateSquatterOptions =
          createReservationQueryData?.dateSquatterExempt?.enumValues;
        const createFormConfirmationStatusOptions =
          createReservationQueryData?.confirmationStatus?.enumValues;
        const createFormEventTypeOptions =
          createReservationQueryData?.eventType?.enumValues;
        const createFormCancellationTypeOptions =
          createReservationQueryData?.cancellationTypeOptions?.enumValues;
        const createFormLargeVehicleOptions =
          createReservationQueryData?.largeVehicles?.enumValues;
        const createFormMobilityChallengedOptions =
          createReservationQueryData?.mobilityChallenged?.enumValues;
        const createFormMidStayCleanOptions =
          createReservationQueryData?.midStayClean?.enumValues;
        const createResortAccessFormOptions =
          createReservationQueryData?.resortAccess?.enumValues;
        const createGuestReservationStatusOptions =
          createReservationQueryData?.guestReservationStatusOptions;
        const allProperties = createReservationQueryData?.properties || [];

        const propertyOptions = allProperties.reduce((acc, property) => {
          if (property.propertyListings.length > 0) {
            acc.push(property);
          }
          return acc;
        }, []);

        return (
          <Query
            variables={{
              skip: !reference,
            }}
            query={PROPERTY_LISTINGS}
          >
            {({ loading, error, data: propertyListingsData, refetch }) => {
              const propertyListings = propertyListingsData?.propertyListings;
              const isUpdate = reference ? true : false;

              return (
                <Query
                  variables={{
                    reference,
                  }}
                  skip={!isUpdate}
                  query={GUEST_RESERVATION}
                  fetchPolicy={'no-cache'}
                >
                  {({
                    loading,
                    error,
                    data: guestReservationData,
                    refetch: refetchReservation,
                  }) => {
                    if (loading)
                      return (
                        <div style={{ maxHeight: '200px' }}>
                          <div className="d-flex justify-content-center position-relative mt-2">
                            <LoadingSpinner customHeight={0} />
                          </div>
                        </div>
                      );
                    if (error) {
                      return (
                        <div className="conatiner-fluid pt-3">
                          {error.message}
                        </div>
                      );
                    }
                    const reservation = guestReservationData?.guestReservation;

                    const finalizationStepOptions =
                      guestReservationData?.guestReservationFinalizationStepOptions;
                    const redFlagOptions =
                      guestReservationData?.guestReservationRedFlagOptions;
                    const dateSquatterFormOptions =
                      guestReservationData?.dateSquatterExempt?.enumValues ||
                      createFormDateSquatterOptions;
                    const agreementSignedFormOptions =
                      guestReservationData?.agreementSigned?.enumValues ||
                      createFormAgreementOptions;
                    const confirmationStatusFormOptions =
                      guestReservationData?.confirmationStatus?.enumValues ||
                      createFormConfirmationStatusOptions;
                    const eventTypeFormOptions =
                      guestReservationData?.eventType?.enumValues ||
                      createFormEventTypeOptions;
                    const cancellationTypeFormOptions =
                      guestReservationData?.cancellationTypeOptions
                        ?.enumValues || createFormCancellationTypeOptions;
                    const largeVehiclesFormOptions =
                      guestReservationData?.largeVehicles?.enumValues ||
                      createFormLargeVehicleOptions;
                    const mobilityChallengedFormOptions =
                      guestReservationData?.mobilityChallenged?.enumValues ||
                      createFormMobilityChallengedOptions;
                    const midStayCleanFormOptions =
                      guestReservationData?.midStayClean?.enumValues ||
                      createFormMidStayCleanOptions;
                    const resortAccessFormOptions =
                      guestReservationData?.resortAccess?.enumValues ||
                      createResortAccessFormOptions;
                    const guestReservationStatusOptions =
                      guestReservationData?.guestReservationStatusOptions ||
                      createGuestReservationStatusOptions;

                    onDataLoad(reservation);

                    const getFinalizeStatusLabel = finalizationSteps => {
                      // const allSteps = addDefaultValuesToFinalizeSteps(topLevelTableData);
                      if (
                        finalizationSteps?.length ===
                        finalizationStepOptions?.length
                      ) {
                        return 'Complete';
                      }
                      return `${finalizationSteps?.length || 0} of ${finalizationStepOptions?.length
                        }`;
                    };

                    const getInitialFormValue = (name, defaultValue) => {
                      switch (name) {
                        case 'agreementSigned': {
                          return reservation?.agreementSigned || undefined;
                        }
                        case 'eventType': {
                          if (reservation) {
                            return reservation?.eventType || undefined;
                          }
                          return undefined;
                        }
                        case 'confirmationStatus': {
                          if (reservation) {
                            return reservation?.confirmationStatus || undefined;
                          }
                          return undefined;
                        }
                        case 'dateSquatterExempt': {
                          if (reservation) {
                            return reservation?.dateSquatterExempt || undefined;
                          }
                          return undefined;
                        }
                        case 'largeVehicles': {
                          if (reservation) {
                            return reservation?.largeVehicles || undefined;
                          }
                          return undefined;
                        }
                        case 'mobilityChallenged': {
                          if (reservation) {
                            // console.log('reservation', reservation);
                            return reservation?.mobilityChallenged || undefined;
                          }
                          return undefined;
                        }
                        case 'midStayClean': {
                          if (reservation) {
                            return reservation?.midStayClean || undefined;
                          }
                          return undefined;
                        }
                        case 'resortAccess': {
                          if (reservation) {
                            return reservation?.resortAccess || undefined;
                          }
                          return undefined;
                        }
                        default:
                          return undefined;
                      }
                    };

                    return (
                      <>
                        <Mutation
                          mutation={
                            isUpdate
                              ? UPDATE_GUEST_RESERVATION
                              : CREATE_GUEST_RESERVATION
                          }
                        >
                          {(
                            guestReservationMutation,
                            {
                              data: updatedGuestReservation,
                              error: mutationError,
                            }
                          ) => {
                            // const tomorrow = new Date();
                            // tomorrow.setDate(tomorrow.getDate() + 1);

                            return (
                              <Formik
                                validationSchema={validationSchema}
                                // enableReinitialize={true}
                                initialValues={{
                                  reference: reference || '',
                                  propertyName:
                                    reservation?.property?.name || '',
                                  // propertyId: isUpdate ? '1' : '', // 1 is an arbitrary number so that theres a value present so it doesn't trip up the validation in update mode
                                  // platformId: isUpdate ? '1' : '', // 1 is an arbitrary number so that theres a value present so it doesn't trip up the validation in update mode

                                  VRPTotalTravelerPayment: isUpdate
                                    ? reservation?.receipt?.VRPTotalTravelerPayment
                                    : '',
                                  propertyId: isUpdate
                                    ? reservation?.property?.id
                                    : '',
                                  platformId: isUpdate
                                    ? reservation?.platform?.id
                                    : '',
                                  platformKey: '',
                                  checkIn: reservation?.checkIn || null,
                                  checkOut: reservation?.checkOut || null,
                                  reservationCreateDate:
                                    reservation?.reservationCreateDate || '',
                                  visitors: reservation?.visitors || 0,
                                  vehicles: reservation?.vehicles || 0,
                                  largeVehicles: getInitialFormValue(
                                    'largeVehicles'
                                  ),
                                  children: reservation?.children || 0,
                                  toddlers: reservation?.toddlers || 0,
                                  preteens: reservation?.preteens || 0,
                                  adults: reservation?.adults || 0,
                                  pets: reservation?.pets || 0,
                                  agreementSigned: getInitialFormValue(
                                    'agreementSigned'
                                  ),

                                  eventType: getInitialFormValue('eventType'),
                                  // resortAccess: reservation?.resortAccess || 'NO',
                                  resortAccess: getInitialFormValue(
                                    'resortAccess'
                                  ),
                                  confirmationStatus: getInitialFormValue(
                                    'confirmationStatus'
                                  ),
                                  cancellationType:
                                    reservation?.cancellationType,
                                  status: reservation?.status || 'BOOKING',
                                  mobilityChallenged: getInitialFormValue(
                                    'mobilityChallenged'
                                  ),
                                  midStayClean: getInitialFormValue(
                                    'midStayClean'
                                  ),
                                  dateSquatterExempt: getInitialFormValue(
                                    'dateSquatterExempt'
                                  ),
                                  finalizationSteps: reservation?.finalizationSteps
                                    ? reservation?.finalizationSteps
                                    : null,
                                }}
                                onSubmit={async (
                                  values,
                                  {
                                    setStatus,
                                    setSubmitting,
                                    setFieldValue,
                                    setErrors,
                                    errors
                                  }
                                ) => {
                                  // console.log('errors', errors)
                                  // return;
                                  delete values.finalizationSteps;
                                  try {
                                    const {
                                      // propertyListingId,
                                      propertyId,
                                      platformId,
                                      children,
                                      toddlers,
                                      preteens,
                                      checkIn,
                                      checkOut,
                                      adults,
                                      confirmationStatus,
                                      eventType,
                                      visitors,
                                      pets,
                                      agreementSigned,
                                      dateSquatterExempt,
                                      cancellationType,
                                      status,
                                      reference,
                                      reservationCreateDate,
                                      largeVehicles,
                                      midStayClean,
                                      mobilityChallenged,
                                      vehicles,
                                      resortAccess,
                                      VRPTotalTravelerPayment
                                    } = values;

                                    const getPropertyListingId = () => {
                                      const foundPropertyListing = propertyListings.find(
                                        listing => {
                                          if (
                                            Number(propertyId) ===
                                            listing?.property?.id &&
                                            Number(platformId) ===
                                            listing?.platform?.id
                                          ) {
                                            return listing;
                                          }
                                        }
                                      );
                                      if (foundPropertyListing) {
                                        return foundPropertyListing?.id;
                                      }
                                      return null;
                                    };
                                    const propertyListingId = getPropertyListingId();
                                    // const propertyListingId = isUpdate
                                    //   ? reservation?.propertyListing?.id
                                    //   : getPropertyListingId();

                                    setSubmitting(true);

                                    const mutationSharedInput = {
                                      guestId,
                                      propertyListingId,
                                      adults: adults === '' ? null : adults,
                                      children:
                                        children === '' ? null : children,
                                      toddlers:
                                      toddlers === '' ? null : toddlers,
                                      preteens:
                                      preteens === '' ? null : preteens,
                                      checkIn,
                                      checkOut,
                                      confirmationStatus: confirmationStatus
                                        ? confirmationStatus
                                        : null,
                                      agreementSigned: agreementSigned || null,
                                      eventType: eventType ? eventType : null,
                                      status,
                                      visitors:
                                        visitors === '' ? null : visitors,
                                      pets: pets === '' ? null : pets,
                                      vehicles:
                                        vehicles === '' ? null : vehicles,
                                      largeVehicles:
                                        largeVehicles === ''
                                          ? null
                                          : largeVehicles,
                                      midStayClean: midStayClean || null,
                                      mobilityChallenged:
                                        mobilityChallenged || null,
                                      resortAccess: resortAccess || null,
                                    };

                                    const getMutationResult = async () => {
                                      if (values.VRPTotalTravelerPayment != reservation?.receipt?.VRPTotalTravelerPayment) {


                                        await client.mutate({
                                          mutation: gql`
                                            mutation UpsertGuestReservationReceipt(
                                              $reference: String!
                                              # $lodgingBaseTotal: Float
                                              # $administrativeFee: Float
                                              # $cleaningFee: Float
                                              # $petFee: Float
                                              # $lodgingTaxesToRemit: Float
                                              # $discount: Float
                                              # $totalPayout: Float
                                              # $bookingAmount: Float
                                              # $paidToVRPFromUs: Float
                                              # $VRPRetainedLodgingTaxes: Float
                                              # $VRPRetainedTravelerServiceFee: Float
                                              $VRPTotalTravelerPayment: Float
                                              # $debug: String
                                            ) {
                                              upsertGuestReservationReceipt(
                                                create: {
                                                  reference: $reference
                                                  # lodgingBaseTotal: $lodgingBaseTotal
                                                  # administrativeFee: $administrativeFee
                                                  # cleaningFee: $cleaningFee
                                                  # petFee: $petFee
                                                  # lodgingTaxesToRemit: $lodgingTaxesToRemit
                                                  # discount: $discount
                                                  # totalPayout: $totalPayout
                                                  # bookingAmount: $bookingAmount
                                                  # paidToVRPFromUs: $paidToVRPFromUs
                                                  # VRPRetainedLodgingTaxes: $VRPRetainedLodgingTaxes
                                                  # VRPRetainedTravelerServiceFee: $VRPRetainedTravelerServiceFee
                                                  VRPTotalTravelerPayment: $VRPTotalTravelerPayment
                                                  # debug: $debug
                                                }
                                                update: {
                                                  reference: $reference
                                                  # lodgingBaseTotal: $lodgingBaseTotal
                                                  # administrativeFee: $administrativeFee
                                                  # cleaningFee: $cleaningFee
                                                  # petFee: $petFee
                                                  # lodgingTaxesToRemit: $lodgingTaxesToRemit
                                                  # discount: $discount
                                                  # totalPayout: $totalPayout
                                                  # bookingAmount: $bookingAmount
                                                  # paidToVRPFromUs: $paidToVRPFromUs
                                                  # VRPRetainedLodgingTaxes: $VRPRetainedLodgingTaxes
                                                  # VRPRetainedTravelerServiceFee: $VRPRetainedTravelerServiceFee
                                                  VRPTotalTravelerPayment: $VRPTotalTravelerPayment
                                                  # debug: $debug
                                                }
                                                where: { reference: $reference }
                                              ) {
                                                id
                                              }
                                            }
                                          `,
                                          variables: {
                                            reference: values.reference,
                                            // lodgingBaseTotal,
                                            // administrativeFee,
                                            // cleaningFee,
                                            // petFee,
                                            // lodgingTaxesToRemit,
                                            // discount,
                                            // totalPayout,
                                            // bookingAmount,
                                            // paidToVRPFromUs,
                                            // VRPRetainedLodgingTaxes,
                                            // VRPRetainedTravelerServiceFee,
                                            VRPTotalTravelerPayment: values.VRPTotalTravelerPayment * 1,
                                            // debug
                                          }
                                        });

                                      }
                                      if (isUpdate) {
                                        return await guestReservationMutation({
                                          variables: {
                                            guestWhereUniqueInput: {
                                              reference,
                                            },
                                            guestReservationUpdateInput: {
                                              dateSquatterExempt,
                                              cancellationType,
                                              ...mutationSharedInput,
                                            },
                                          },
                                        });
                                      } else {
                                        return await guestReservationMutation({
                                          variables: {
                                            guestReservationCreateInput: {
                                              guestId,
                                              propertyListingId,
                                              reference,
                                              checkIn,
                                              checkOut,
                                              reservationCreateDate,
                                              ...mutationSharedInput,
                                            },
                                          },
                                        });
                                      }
                                    };

                                    const mutationResult = await getMutationResult();

                                    if (mutationResult) {
                                      setStatus({ success: true });
                                      setSubmitting(false);
                                      onSuccess();
                                      refetchReservation();
                                    }
                                  } catch (err) {
                                    console.log('err', err);

                                    setSubmitting(false);
                                    setStatus({ error: err });
                                  }

                                  return true;
                                }}
                              >
                                {({
                                  errors,
                                  touched,
                                  resetForm,
                                  isSubmitting,
                                  values,
                                  status,
                                  setFieldValue,
                                  setSubmitting,
                                  submitCount,
                                }) => {
                                  const hasFinalizationSteps =
                                    values?.finalizationSteps ||
                                    reservation?.finalizationSteps ||
                                    finalizationStepsState;
                                  const StatusColumn = () => {
                                    return (
                                      <div className="col col-12 col-md-6">
                                        <div className="form-group">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                              <label className="">Status</label>
                                              <span className="text-danger ml-1">
                                                *
                                              </span>
                                            </div>

                                            {hasFinalizationSteps && (
                                              <div className="mb-2">
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    setFinalizeStatusModalIsOpen(
                                                      true
                                                    );
                                                  }}
                                                  className="btn btn-link p-0 small"
                                                >
                                                  Finalized Status (
                                                  {getFinalizeStatusLabel(
                                                    finalizationStepsState ||
                                                    reservation?.finalizationSteps
                                                  )}
                                                  )
                                                </button>
                                              </div>
                                            )}
                                          </div>

                                          <Field
                                            as="select"
                                            name="status"
                                            // readOnly={true}
                                            // value={values?.platformKey}
                                            // onChange={handleChange}
                                            className={`form-control ${errors.status &&
                                              touched.status &&
                                              submitCount > 0 &&
                                              'is-invalid hide-background-image'}`}
                                          >
                                            <option hidden value="">
                                              Choose One
                                            </option>
                                            {guestReservationStatusOptions &&
                                              guestReservationStatusOptions.map(
                                                (option, index) => {
                                                  if (
                                                    !option?.type ||
                                                    option?.type === ''
                                                  ) {
                                                    return null;
                                                  }

                                                  return (
                                                    <option
                                                      key={index}
                                                      value={option?.type}
                                                    // defaultValue={true}
                                                    >
                                                      {enumToLabel(
                                                        option?.type
                                                      )}
                                                    </option>
                                                  );
                                                }
                                              )}
                                          </Field>

                                          <ErrorMessage
                                            component="div"
                                            name="status"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                    );
                                  };
                                  return (
                                    <Form id="guest-reservation-form">
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <label>Reference </label>
                                                <span className="text-danger ml-1">
                                                  *
                                                </span>
                                              </div>
                                              <TYFRFormLinkIcon
                                                reference={values.reference}
                                              />
                                            </div>
                                            <Field
                                              readOnly={isUpdate ? true : false}
                                              name="reference"
                                              type="text"
                                              className={`form-control ${errors.reference &&
                                                touched.reference &&
                                                submitCount > 0 &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.reference}
                                              placeholder="ex: HA-1234AB, HM124567"
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="reference"
                                              className="invalid-feedback"
                                            />
                                            {/* <div className="small text-danger mt-1">
                                            {touched.reference &&
                                              errors.reference &&
                                              errors.reference}
                                          </div> */}
                                          </div>
                                        </div>

                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Platform</label>
                                            <span className="text-danger ml-1">
                                              *
                                            </span>
                                            {isUpdate && (
                                              <Field
                                                name="platformName"
                                                readOnly={true}
                                                type="text"
                                                className={`form-control ${errors.platformName &&
                                                  touched.platformName &&
                                                  submitCount > 0 &&
                                                  'is-invalid hide-background-image'}`}
                                                value={
                                                  reservation?.platform?.name
                                                }
                                                placeholder=""
                                              />
                                            )}

                                            {!isUpdate && platforms && (
                                              <Field
                                                as="select"
                                                name="platformId"
                                                // readOnly={true}
                                                // value={values?.platformKey}
                                                // onChange={handleChange}
                                                className={`form-control ${errors.platformId &&
                                                  touched.platformId &&
                                                  submitCount > 0 &&
                                                  'is-invalid hide-background-image'}`}
                                              >
                                                <option hidden value="">
                                                  Choose One
                                                </option>
                                                {platforms.map(
                                                  (platform, index) => {
                                                    if (
                                                      platform?.key ===
                                                      'homeaway'
                                                    ) {
                                                      return (
                                                        <option
                                                          key={index}
                                                          value={platform?.id}
                                                        // defaultValue={true}
                                                        >
                                                          {'VRBO'}
                                                        </option>
                                                      );
                                                    }
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={platform?.id}
                                                      >
                                                        {platform.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Field>
                                            )}
                                            <ErrorMessage
                                              component="div"
                                              name="platformId"
                                              className="invalid-feedback"
                                            />
                                            {/* <div className="small text-danger mt-1">
                                            {touched.platformId &&
                                              errors.platformId &&
                                              errors.platformId}
                                          </div> */}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Property</label>
                                            <span className="text-danger ml-1">
                                              *
                                            </span>
                                            {/* {isUpdate && (
                                              <Field
                                                name="propertyName"
                                                readOnly={
                                                  isUpdate ? true : false
                                                }
                                                type="text"
                                                className={`form-control ${errors.propertyName &&
                                                  touched.propertyName &&
                                                  'is-invalid hide-background-image'}`}
                                                value={values?.propertyName}
                                                placeholder=""
                                              />
                                            )} */}
                                            {(
                                              <Field
                                                as="select"
                                                name="propertyId"
                                                className={`form-control ${errors.propertyId &&
                                                  touched.propertyId &&
                                                  submitCount > 0 &&
                                                  'is-invalid hide-background-image'}`}
                                              // readOnly={true}
                                              // onChange={e => {
                                              //   e.stopPropagation();
                                              // }}
                                              // value={values.propertyListingId}
                                              >
                                                <option value="">
                                                  Choose One
                                                </option>
                                                {propertyOptions
                                                  // .filter(
                                                  //   property =>
                                                  //     property.name.indexOf('+') < 0
                                                  // )
                                                  .map((property, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={property.id}
                                                      >
                                                        {property.name}
                                                      </option>
                                                    );
                                                  })}
                                              </Field>
                                            )}
                                            <ErrorMessage
                                              component="div"
                                              name="propertyId"
                                              className="invalid-feedback"
                                            />
                                            {/* <div className="small text-danger mt-1">
                                            {touched.propertyId &&
                                              errors.propertyId &&
                                              errors.propertyId}
                                          </div> */}
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div
                                            className="form-group d-flex"
                                            id="dates-form-group"
                                          >
                                            <div className="mr-3 w-100">
                                              <label>Check In</label>
                                              <span className="text-danger ml-1">
                                                *
                                              </span>
                                              {/* {isUpdate && (
                                                <Field
                                                  name="checkIn"
                                                  readOnly={true}
                                                  type="text"
                                                  className={`form-control ${errors.checkIn &&
                                                    touched.checkIn &&
                                                    'is-invalid hide-background-image'}`}
                                                  value={
                                                    values?.checkIn
                                                      ? moment(
                                                        values?.checkIn
                                                      ).format('MM/DD/YYYY')
                                                      : ''
                                                  }
                                                  placeholder=""
                                                />
                                              )} */}

                                              {(
                                                <DatePicker
                                                  className={`form-control ${errors.checkIn &&
                                                    touched.checkIn &&
                                                    submitCount > 0 &&
                                                    'is-invalid hide-background-image'}`}
                                                  selected={
                                                    values.checkIn
                                                      ? new Date(values.checkIn)
                                                      : null
                                                  }
                                                  startDate={
                                                    values.checkIn
                                                      ? new Date(values.checkIn)
                                                      : null
                                                  }
                                                  minDate={new Date()}
                                                  // endDate={
                                                  //   values.checkOut
                                                  //     ? new Date(values.checkOut)
                                                  //     : null
                                                  // }
                                                  selectsStart
                                                  onChange={args => {
                                                    setFieldValue(
                                                      'checkIn',
                                                      args
                                                    );
                                                    // setFieldValue(
                                                    //   'checkOut',
                                                    //   new Date(
                                                    //     args.getTime() +
                                                    //       1 * 24 * 60 * 60 * 1000
                                                    //   )
                                                    // );
                                                  }}
                                                  dateFormat="MM/dd/yyyy"
                                                  placeHolderText="Select a Date"
                                                />
                                              )}
                                              {/* <ErrorMessage
                                              component="div"
                                              name="checkIn"
                                              className="invalid-feedback"
                                            /> */}
                                              <div className="small text-danger mt-1">
                                                {touched.checkIn &&
                                                  errors.checkIn &&
                                                  errors.checkIn}
                                              </div>
                                            </div>
                                            <div className="w-100">
                                              <label>Check Out</label>
                                              <span className="text-danger ml-1">
                                                *
                                              </span>
                                              {/* {isUpdate && (
                                                <Field
                                                  name="checkOut"
                                                  readOnly={true}
                                                  type="text"
                                                  className={`form-control ${errors.checkOut &&
                                                    touched.checkOut &&
                                                    submitCount < 0 &&
                                                    'is-invalid hide-background-image'}`}
                                                  value={
                                                    values?.checkOut
                                                      ? moment(
                                                        values?.checkOut
                                                      ).format('MM/DD/YYYY')
                                                      : ''
                                                  }
                                                  placeholder=""
                                                />
                                              )} */}

                                              { (
                                                <DatePicker
                                                  popperPlacement="top-end"
                                                  className={`form-control ${errors.checkOut &&
                                                    touched.checkOut &&
                                                    'is-invalid hide-background-image'}`}
                                                  selected={
                                                    values.checkOut
                                                      ? new Date(
                                                        values.checkOut
                                                      )
                                                      : null
                                                  }
                                                  startDate={
                                                    values.checkIn
                                                      ? new Date(values.checkIn)
                                                      : null
                                                  }
                                                  endDate={
                                                    values.checkOut
                                                      ? new Date(
                                                        values.checkOut
                                                      )
                                                      : null
                                                  }
                                                  selectsEnd
                                                  minDate={
                                                    values.checkIn
                                                      ? values.checkIn
                                                      : new Date()
                                                  }
                                                  onChange={args => {
                                                    setFieldValue(
                                                      'checkOut',
                                                      args
                                                    );
                                                  }}
                                                  dateFormat="MM/dd/yyyy"
                                                  placeHolderText="Select a Date"
                                                />
                                              )}
                                              {/* <ErrorMessage
                                              component="div"
                                              name="checkOut"
                                              className="invalid-feedback"
                                            /> */}
                                              <div className="small text-danger mt-1">
                                                {touched.checkOut &&
                                                  errors.checkOut &&
                                                  errors.checkOut}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Adults</label>
                                            <Field
                                              name="adults"
                                              type="number"
                                              className={`form-control ${errors.adults &&
                                                touched.adults &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.adults}
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="adults"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div className="row">
                                            <div className="col col-12 col-md-4">
                                              <div className="form-group">
                                                <label>Children <span style={{  'fontWeight': 'normal',  'fontSize': '12px'}}>(Total)<span className="text-danger">*</span></span></label>
                                                  <Field
                                                    name="children"
                                                    type="number"
                                                    className={`form-control ${errors.children &&
                                                      touched.children &&
                                                      'is-invalid hide-background-image'}`}
                                                    value={values.children}
                                                    placeholder=""
                                                  />
                                                  <ErrorMessage
                                                    component="div"
                                                    name="children"
                                                    className="invalid-feedback"
                                                  />
                                              </div>
                                            </div>
                                            <div className="col col-12 col-md-4">
                                              <div className="form-group">
                                                <label>Ages 0-3</label>
                                                  <Field
                                                    name="toddlers"
                                                    type="number"
                                                    className={`form-control ${errors.toddlers &&
                                                      touched.toddlers &&
                                                      'is-invalid hide-background-image'}`}
                                                    value={values.toddlers}
                                                    placeholder=""
                                                  />
                                                  <ErrorMessage
                                                    component="div"
                                                    name="toddlers"
                                                    className="invalid-feedback"
                                                  />
                                              </div>
                                            </div>
                                            <div className="col col-12 col-md-4">
                                              <div className="form-group">
                                                <label>Ages 4-17</label>
                                                  <Field
                                                    name="preteens"
                                                    type="number"
                                                    className={`form-control ${errors.preteens &&
                                                      touched.preteens &&
                                                      'is-invalid hide-background-image'}`}
                                                    value={values.preteens}
                                                    placeholder=""
                                                  />
                                                  <ErrorMessage
                                                    component="div"
                                                    name="preteens"
                                                    className="invalid-feedback"
                                                  />
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Pets</label>
                                            <Field
                                              name="pets"
                                              type="number"
                                              className={`form-control ${errors.pets &&
                                                touched.pets &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.pets}
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="pets"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Visitors</label>
                                            <Field
                                              name="visitors"
                                              type="number"
                                              className={`form-control ${errors.visitors &&
                                                touched.visitors &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.visitors}
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="visitors"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Vehicles</label>
                                            <Field
                                              name="vehicles"
                                              type="number"
                                              className={`form-control ${errors.vehicles &&
                                                touched.vehicles &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.vehicles}
                                              placeholder=""
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="vehicles"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Large Vehicles</label>
                                            <Field
                                              name="largeVehicles"
                                              as="select"
                                              type="text"
                                              className={`form-control ${errors.largeVehicles &&
                                                touched.largeVehicles &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.largeVehicles}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {largeVehiclesFormOptions &&
                                                largeVehiclesFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="dateSquatterExempt"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Confirmed</label>

                                            <Field
                                              name="confirmationStatus"
                                              as="select"
                                              type="text"
                                              className={`form-control ${errors.confirmationStatus &&
                                                touched.confirmationStatus &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.confirmationStatus}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {confirmationStatusFormOptions &&
                                                confirmationStatusFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="confirmationStatus"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Agreement Signed</label>
                                            <Field
                                              name="agreementSigned"
                                              as="select"
                                              type="text"
                                              className={`form-control ${errors.agreementSigned &&
                                                touched.agreementSigned &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.agreementSigned}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {agreementSignedFormOptions &&
                                                agreementSignedFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="agreementSigned"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Event Type</label>
                                            <Field
                                              name="eventType"
                                              as="select"
                                              // type="text"
                                              className={`form-control ${errors.eventType &&
                                                touched.eventType &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.eventType}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {eventTypeFormOptions &&
                                                eventTypeFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="eventType"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>Resort Access</label>
                                            <Field
                                              name="resortAccess"
                                              as="select"
                                              type="text"
                                              className={`form-control ${errors.resortAccess &&
                                                touched.resortAccess &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.resortAccess}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {resortAccessFormOptions &&
                                                resortAccessFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="resortAccess"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div className="form-group">
                                            <label>
                                              Mobility Issues Indicated
                                            </label>
                                            <Field
                                              name="mobilityChallenged"
                                              as="select"
                                              type="text"
                                              className={`form-control ${errors.mobilityChallenged &&
                                                touched.mobilityChallenged &&
                                                'is-invalid hide-background-image'}`}
                                              value={values.mobilityChallenged}
                                              placeholder=""
                                            >
                                              <option hidden value="">
                                                Choose One
                                              </option>
                                              {mobilityChallengedFormOptions &&
                                                mobilityChallengedFormOptions.map(
                                                  _enum => {
                                                    return (
                                                      <option
                                                        value={_enum.name}
                                                        key={_enum.name}
                                                      >
                                                        {enumToLabel(
                                                          _enum.name
                                                        )}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              name="dateSquatterExempt"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </div>
                                        {!isUpdate && <StatusColumn />}
                                        {isUpdate && (
                                          <div className="col col-12 col-md-6">
                                            <div className="form-group">
                                              <label>
                                                Date Squatter Exempt
                                              </label>

                                              <Field
                                                name="dateSquatterExempt"
                                                as="select"
                                                type="text"
                                                className={`form-control ${errors.dateSquatterExempt &&
                                                  touched.dateSquatterExempt &&
                                                  'is-invalid hide-background-image'}`}
                                                value={
                                                  values.dateSquatterExempt
                                                }
                                                placeholder=""
                                              >
                                                <option hidden value="">
                                                  Choose One
                                                </option>
                                                {dateSquatterFormOptions &&
                                                  dateSquatterFormOptions.map(
                                                    _enum => {
                                                      return (
                                                        <option
                                                          value={_enum.name}
                                                          key={_enum.name}
                                                        >
                                                          {enumToLabel(
                                                            _enum.name
                                                          )}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </Field>
                                              <ErrorMessage
                                                component="div"
                                                name="dateSquatterExempt"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="row">
                                        <div className="col col-12 col-md-6">
                                          <div
                                            className="form-group"
                                            id="reservation-create-date-wrapper"
                                          >
                                            <div>
                                              <label>Mid-Stay Clean</label>

                                              <Field
                                                name="midStayClean"
                                                as="select"
                                                type="text"
                                                className={`form-control ${errors.midStayClean &&
                                                  touched.midStayClean &&
                                                  'is-invalid hide-background-image'}`}
                                                value={values.midStayClean}
                                                placeholder=""
                                              >
                                                <option hidden value="">
                                                  Choose One
                                                </option>
                                                {midStayCleanFormOptions &&
                                                  midStayCleanFormOptions.map(
                                                    _enum => {
                                                      return (
                                                        <option
                                                          value={_enum.name}
                                                          key={_enum.name}
                                                        >
                                                          {enumToLabel(
                                                            _enum.name
                                                          )}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                              </Field>
                                              <ErrorMessage
                                                component="div"
                                                name="midStayClean"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col col-12 col-md-6">
                                          <div
                                            className="form-group"
                                            id="reservation-create-date-wrapper"
                                          >
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                <label>
                                                  Reservation Create Date
                                                </label>
                                                <span className="text-danger ml-1">
                                                  *
                                                </span>
                                              </div>
                                              {reservation?.externalConversationUrl && (
                                                <div className="mb-2">
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      reservation?.externalConversationUrl
                                                    }
                                                    className="small"
                                                  >
                                                    View Conversation{' '}
                                                    <ExternalLink size={18} />
                                                  </a>
                                                </div>
                                              )}
                                            </div>
                                            {isUpdate && (
                                              <Field
                                                name="reservationCreateDate"
                                                readOnly={true}
                                                type="text"
                                                className={`form-control ${errors.reservationCreateDate &&
                                                  touched.reservationCreateDate &&
                                                  'is-invalid hide-background-image'}`}
                                                value={
                                                  values?.reservationCreateDate
                                                    ? moment(
                                                      values?.reservationCreateDate
                                                    ).format('MM/DD/YYYY')
                                                    : ''
                                                }
                                                placeholder=""
                                              />
                                            )}

                                            {!isUpdate && (
                                              <DatePicker
                                                className={`form-control d-block ${errors.reservationCreateDate &&
                                                  touched.reservationCreateDate &&
                                                  'is-invalid hide-background-image'}`}
                                                selected={
                                                  values.reservationCreateDate
                                                    ? new Date(
                                                      values.reservationCreateDate
                                                    )
                                                    : null
                                                }
                                                startDate={
                                                  values.reservationCreateDate
                                                    ? new Date(
                                                      values.reservationCreateDate
                                                    )
                                                    : null
                                                }
                                                selectsEnd
                                                // minDate={
                                                //   values.reservationCreateDate
                                                //     ? values.reservationCreateDate
                                                //     : new Date()
                                                // }
                                                onChange={args => {
                                                  setFieldValue(
                                                    'reservationCreateDate',
                                                    args
                                                  );
                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                placeHolderText="Select a Date"
                                              />
                                            )}

                                            {/* <ErrorMessage
                                              component="div"
                                              name="checkOut"
                                              className="invalid-feedback"
                                            /> */}
                                            <div className="small text-danger mt-1">
                                              {touched.reservationCreateDate &&
                                                errors.reservationCreateDate &&
                                                errors.reservationCreateDate}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {isUpdate && (
                                        <>
                                          <div className="row">
                                            {' '}
                                            <StatusColumn />
                                            <div className="col col-12 col-md-6">
                                              <div className="form-group">
                                                <label className="mb-2">
                                                  Geo-related Reservations
                                                  <Tooltip
                                                    label={
                                                      'Reservation holders live near each other and overlap travel days, indicating a possibly larger group or event.'
                                                    }
                                                    placement="top"
                                                    id={`geo-related-tooltip`}
                                                    // hide={!isMenuCollapsed}
                                                    className="ml-1"
                                                  >
                                                    <Info
                                                      width={17}
                                                      className="feather-info info info-icon"
                                                    />
                                                  </Tooltip>
                                                </label>
                                                <div
                                                  className="form-control"
                                                  style={{ height: 'auto' }}
                                                >
                                                  {reservation
                                                    ?.geoRelatedReservations
                                                    .length
                                                    ? reservation?.geoRelatedReservations.map(
                                                      reservation => {
                                                        return (
                                                          <a
                                                            href={`https://www.bhipmanagement.com/guests/${reservation.guest.id}`}
                                                            target="_blank"
                                                            className="mr-3"
                                                          >
                                                            {
                                                              reservation.reference
                                                            }
                                                          </a>
                                                        );
                                                      }
                                                    )
                                                    : 'None'}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col col-12 col-md-6">
                                              <div className="form-group">
                                                <label>Cancellation Type</label>
                                                <Field
                                                  name="cancellationType"
                                                  as="select"
                                                  disabled={
                                                    values.status != 'CANCELLED'
                                                      ? true
                                                      : false
                                                  }
                                                  // type="text"
                                                  className={`form-control ${errors.cancellationType &&
                                                    touched.cancellationType &&
                                                    'is-invalid hide-background-image'}`}
                                                  value={
                                                    values.cancellationType
                                                  }
                                                  placeholder=""
                                                >
                                                  <option hidden value="">
                                                    Choose One
                                                  </option>
                                                  {cancellationTypeFormOptions &&
                                                    cancellationTypeFormOptions.map(
                                                      _enum => {
                                                        return (
                                                          <option
                                                            value={_enum.name}
                                                            key={_enum.name}
                                                          >
                                                            {enumToLabel(
                                                              _enum.name.replace(
                                                                /MGMT_/,
                                                                '098_'
                                                              )
                                                            )}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </Field>
                                                <ErrorMessage
                                                  component="div"
                                                  name="cancellationType"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </div>
                                            <div className="col col-12 col-md-6">
                                              <div className="form-group">
                                                <label>Gross Rate</label>
                                                <Field
                                                  name="VRPTotalTravelerPayment"

                                                  disabled={
                                                    reservation?.receipt?.VRPTotalTravelerPayment
                                                      ? true
                                                      : false
                                                  }
                                                  // type="text"
                                                  className={`form-control ${errors.VRPTotalTravelerPayment &&
                                                    touched.VRPTotalTravelerPayment &&
                                                    'is-invalid hide-background-image'}`}
                                                  value={
                                                    values.VRPTotalTravelerPayment
                                                  }
                                                  placeholder=""
                                                >

                                                </Field>
                                                <ErrorMessage
                                                  component="div"
                                                  name="VRPTotalTravelerPayment"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      {reservation?.redFlags && (
                                        <section>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                              <label className="font-weight-bold">
                                                🚩 Red Flags
                                              </label>
                                            </div>

                                            <button
                                              type="button"
                                              onClick={e => {
                                                setRedFlagsModalIsOpen(true);
                                              }}
                                              className={`btn btn-link small`}
                                            >
                                              Edit
                                            </button>
                                          </div>

                                          {reservation?.redFlags?.length >
                                            0 && (
                                              <div className="card p-1 mb-3">
                                                <ul className="row mb-0">
                                                  {(
                                                    redFlagsState ||
                                                    reservation?.redFlags
                                                  ).map(flag => {
                                                    return (
                                                      <li
                                                        key={flag?.label}
                                                        className="col col-12 col-md-6 "
                                                      >
                                                        {' '}
                                                        <span>{flag?.label}</span>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </div>
                                            )}
                                        </section>
                                      )}
                                      {reservation?.platform.key == 'bhip' && <>
                                        <label class="font-weight-bold mt-2">
                                          Payment Requests
                                        </label>
                                        <div className="card p-1 mb-3">
                                          <table style={{ width: '100%' }}>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reservation?.paymentRequests?.length > 0 ? (

                                                <>
                                                  {reservation?.paymentRequests.map(
                                                    entry => {
                                                      return (
                                                        <tr>
                                                          <td>
                                                            {moment(
                                                              entry.date
                                                            ).format(
                                                              'MM/DD/YYYY'
                                                            )}
                                                          </td>
                                                          <td>
                                                            {entry.status}
                                                          </td>
                                                          <td>
                                                            {
                                                            entry.externalPaymentUrl ? <a target="_BLANK" href={`${entry.externalPaymentUrl}`}>{entry.description}</a> : entry.description
                                                             
                                                             }
                                                          </td>
                                                          <td className="text-right">
                                                            {entry.amount}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}

                                                </>
                                              ) : (

                                                <tr>
                                                  <td colSpan={9}>

                                                    <>No Entries</>
                                                  </td>
                                                </tr>

                                              )}
                                                  {/* <tr>
                                                  <td colSpan={9} className="text-center">
                                                    <button type="button" className="mt-2 btn btn-link">Attach Invoice</button>
                                                  </td>
                                                </tr> */}
                                            </tbody>
                                          </table>
                                        </div>
                                      </>}



                                      <label class="font-weight-bold">
                                        Form Log
                                      </label>
                                      <div className="card p-1 mb-3">
                                        {reservation?.formEntries.length > 0 ? (
                                          <table style={{ width: '30%' }}>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {reservation?.formEntries.map(
                                                entry => {
                                                  return (
                                                    <tr>
                                                      <td>
                                                        {moment(
                                                          entry.createdAt
                                                        ).format(
                                                          'MM/DD/YYYY hh:mm A'
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Tooltip
                                                          label="TYFR Form Response"
                                                          placement="top"
                                                        >
                                                          <a
                                                            target="_blank"
                                                            href={`/webforms/${entry.id}`}
                                                          >
                                                            {entry.formName}
                                                          </a>
                                                        </Tooltip>
                                                        {/* {TYFRModalIsOpen ==
                                                          entry.id && (
                                                          <TYFRFormEntryModal
                                                            isOpen={
                                                              TYFRModalIsOpen
                                                            }
                                                            webformEntryId={
                                                              entry.id
                                                            }
                                                            onClose={() => {
                                                              setTYFRModalIsOpen(
                                                                false
                                                              );
                                                            }}
                                                          />
                                                        )} */}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <>No Entries</>
                                        )}
                                      </div>
                                      <FormResponse
                                        errors={status?.error}
                                        isVisible={
                                          status?.error ||
                                          status?.success ||
                                          (Object.keys(errors).length > 0 &&
                                            submitCount > 0)
                                        }
                                        alert={
                                          Object.keys(errors).length > 0 &&
                                          submitCount > 0
                                        }
                                      >
                                        {status?.success}
                                      </FormResponse>
                                      <ThemedButton
                                        type="submit"
                                        loading={loading || isSubmitting}
                                        className="w-100 btn btn-outline-primary"
                                      >
                                        Save Changes
                                      </ThemedButton>
                                    </Form>
                                  );
                                }}
                              </Formik>
                            );
                          }}
                        </Mutation>

                        <Modal
                          title={`Red Flags`}
                          isOpen={redFlagsModalIsOpen}
                          onClose={() => {
                            setRedFlagsModalIsOpen(false);
                          }}
                          styleType="large"
                          disableForceFocus={true}
                        >
                          <div id="red-flags-form">
                            <RedFlagsForm
                              selectedRedFlags={
                                redFlagsState || reservation?.redFlags
                              }
                              redFlags={
                                guestReservationRedFlagOptions || redFlagOptions
                              }
                              reference={reference}
                              onSuccess={updatedRedFlags => {
                                setRedFlagsState(updatedRedFlags);
                              }}
                            />
                          </div>
                        </Modal>

                        <Modal
                          title={
                            <p className="font-weight-bold mb-0">
                              Finalized Status (
                              {getFinalizeStatusLabel(
                                finalizationStepsState ||
                                reservation?.finalizationSteps
                              )}
                              )
                            </p>
                          }
                          isOpen={finalizeStatusModalIsOpen}
                          onClose={() => {
                            setFinalizeStatusModalIsOpen(false);
                          }}
                          styleType="large"
                          disableForceFocus={true}
                        >
                          <FinalizedOptionsForm
                            reference={reference}
                            finalizationSteps={
                              finalizationStepsState ||
                              reservation?.finalizationSteps
                            }
                            finalizationOptions={finalizationStepOptions}
                            onSuccess={({ finalizationSteps }) => {
                              setFinalizationStepsState(finalizationSteps);
                            }}
                          />
                        </Modal>
                      </>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
});

GuestReservationForm.defaultProps = {
  onSuccess: () => { },
  onDataLoad: () => { },
};

export default GuestReservationForm;
