import React, { useState, useEffect } from 'react';
import Loadable from '@loadable/component';
import { formatDateWithSlashes } from '../../../../util/dateHelper';
import "./styles.scss";
const DatePicker = Loadable(() => import('../../../../components/DatePicker'));

const VrboSearchForm = ({ onClose }) => {
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [checkInDatepickerOpen, setCheckInDatepickerOpen] = useState(false);
  const [checkOutDatepickerOpen, setCheckOutDatepickerOpen] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [datesChanged, setDatesChanged] = useState(false);

  const generateSearchUrl = () => {
    // Base VRBO search URL with additional parameters from screenshot
    let searchUrl = 'https://www.vrbo.com/search?adults=2&allowPreAppliedFilters=true&amenities=PETS&bedroom_count_gt=6&destination=Hutchinson%20Island%20South%2C%20Jensen%20Beach%2C%20Florida%2C%20United%20States%20of%20America&free_cancellation_group=free_cancellation&house_rules_group=pets_allowed&premier_host_group=premier_host&property_type_group=house&regionId=553248635976007386&semdtl=&sort=RECOMMENDED&theme=&userIntent=';
    
    // Add dates if provided
    if (checkIn && checkOut) {
      const formattedCheckIn = new Date(checkIn).toISOString().split('T')[0];
      const formattedCheckOut = new Date(checkOut).toISOString().split('T')[0];
      searchUrl += `&startDate=${formattedCheckIn}&endDate=${formattedCheckOut}`;
    }

    setGeneratedUrl(searchUrl);
    setDatesChanged(false);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generatedUrl);
      setShowCopiedMessage(true);
      setTimeout(() => setShowCopiedMessage(false), 3000); // Hide after 3 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const openInNewWindow = () => {
    window.open(generatedUrl, '_blank');
  };

  const handleDateChange = (date, type) => {
    if (type === 'checkIn') {
      setCheckIn(date);
      if (checkOut && new Date(date) >= new Date(checkOut)) {
        setCheckOut('');
      }
    } else {
      setCheckOut(date);
      if (checkIn && new Date(date) <= new Date(checkIn)) {
        setCheckIn('');
      }
    }
    setDatesChanged(true);
  };

  return (
    <div className="vrbo-search-form">
      <div className="container px-0">
        <div className="row">
          <div className="col-6 form-group pr-2">
            <p className="font-weight-bold mb-1">Check In (optional)</p>
            <button
              style={{ minHeight: '38px' }}
              type="button"
              className="btn w-100 text-left border"
              onClick={() => setCheckInDatepickerOpen(true)}
            >
              {checkIn ? formatDateWithSlashes(checkIn) : ''}
            </button>
            {checkInDatepickerOpen && (
              <DatePicker
                allowSameDay={true}
                onClickOutside={() => setCheckInDatepickerOpen(false)}
                selectsStart
                startDate={checkIn}
                endDate={checkOut}
                selected={checkIn}
                name="checkIn"
                type="text"
                className="form-control rounded"
                id="checkIn"
                withPortal
                inline
                minDate={new Date()}
                onChange={date => {
                  handleDateChange(date, 'checkIn');
                  setCheckInDatepickerOpen(false);
                }}
              />
            )}
          </div>

          <div className="col-6 form-group pl-0">
            <p className="font-weight-bold mb-1">Check Out (optional)</p>
            <button
              style={{ minHeight: '38px' }}
              type="button"
              className="btn w-100 text-left border"
              onClick={() => setCheckOutDatepickerOpen(true)}
            >
              {checkOut ? formatDateWithSlashes(checkOut) : ''}
            </button>
            {checkOutDatepickerOpen && (
              <DatePicker
                allowSameDay={true}
                onClickOutside={() => setCheckOutDatepickerOpen(false)}
                selectsEnd
                startDate={checkIn}
                endDate={checkOut}
                selected={checkOut}
                name="checkOut"
                type="text"
                className="form-control rounded"
                id="checkOut"
                withPortal
                inline
                minDate={checkIn || new Date()}
                onChange={date => {
                  handleDateChange(date, 'checkOut');
                  setCheckOutDatepickerOpen(false);
                }}
              />
            )}
          </div>
        </div>

        {(!generatedUrl || datesChanged) && (
          <div className="row mt-3">
            <div className="col">
              <button
                onClick={generateSearchUrl}
                className="btn btn-outline-primary rounded w-100"
              >
                Click to Generate Link
              </button>
            </div>
          </div>
        )}

        {generatedUrl && !datesChanged && (
          <>
            <div className="row mt-3">
              <div className="col">
                <div className="generated-url">
                  {generatedUrl}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <button
                  onClick={handleCopyToClipboard}
                  className="btn btn-outline-primary rounded w-100"
                >
                  Click to Copy Link
                </button>
                <div className="text-center mt-2">
                  <a href="#" onClick={openInNewWindow} className="open-link">
                    Open in new window
                  </a>
                </div>
              </div>
            </div>

            {showCopiedMessage && (
              <div className="row mt-3">
                <div className="col">
                  <div className="copied-message alert alert-success">
                    Copied to Clipboard
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VrboSearchForm;
