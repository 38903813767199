import React, { useEffect, useState, useRef } from 'react';
import ScrollTable from '../../../../components/ScrollTable';
import Arrow from 'src/images/arrow.svg';
import ArrowUp from 'src/images/arrow-up.svg';
import BHIP from 'src/images/bhip.svg';
import YouTube from 'src/images/youtube.svg';
import MatterPort from 'src/images/matterport.svg';
import FloorPlan from 'src/images/floor-plan.svg';
import AirBnb from 'src/images/airbnb-logo.svg';
import TripAdvisor from 'src/images/tripadvisor-logo.svg';
import VRBO from 'src/images/vrbo-logo.svg';
import Booking from 'src/images/booking-logo.svg';
import AmenityYes from 'src/images/amenity-yes.svg';
import AmenityNo from 'src/images/amenity-no.svg';
import Star from 'src/images/star.svg';
import Modal from 'src/components/Modal';
import QuoteGenerator from 'src/components/QuoteGenerator';
import './styles.scss';
import get from 'lodash/get';
import BiaxialGrid from 'src/components/BiaxialGrid';
import earlyPayout from '../../../../images/early-payout.svg';
const OverviewTable = ({ properties }) => {
  const [sortBy, setSortBy] = useState(null);
  const [reverse, setReverse] = useState(false);
  const [sleepsModalIsOpen, setSleepsModalIsOpen] = useState(false);
  const [quoteModalIsOpen, setQuoteModalIsOpen] = useState(false);
  const [targetProperty, setTargetProperty] = useState(null);

  const orderBy = key => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  };
  const setOrderByData = sortKey => {
    sortBy === sortKey ? setReverse(prevState => !prevState) : setReverse(true);
    setSortBy(() => sortKey);
  };
  useEffect(() => {
    const onHorizontalScroll = () => {
      const $containerPosition = $(`.property-overview-table`).position() || {};
      const leftPosition = $containerPosition.left;
      const $tableHeadersTh = $('[data-scrollable-row] thead th');
      const $tableHeadersTd = $('[data-scrollable-row] thead td');

      if (leftPosition === 0) {
        $tableHeadersTh.each((index, item) => {
          // if ($(item).hasClass('header-bottom-cell')) {
          //   $(item).css('top', '33px');
          // } else {
          //   $(item).css('top', '0px');
          // }
          // $(item).css('position', 'sticky');

          $(item).css('visibility', 'visible');
        });
        $tableHeadersTd.each((index, item) => {
          $(item).css('visibility', 'visible');
        });
      } else {
        $tableHeadersTh.each((index, item) => {
          // $(item).css('top', '0px');
          $(item).css('position', 'relative');
          if (index > 0) {
            $(item).css('visibility', 'hidden');
          }
        });
        $tableHeadersTd.each((index, item) => {
          if (index > 0) {
            $(item).css('visibility', 'hidden');
          }
        });
      }
    };
    $(window).on('click', '[data-scrollable-row]', () => {
      alert('clicked!!');
    });
    $('[data-fixed-row]').on('scroll', onHorizontalScroll);

    return () => {
      $('[data-fixed-row]').off('scroll', onHorizontalScroll);
    };
  });
  const isFirefox = navigator?.userAgent?.indexOf('Firefox') > 0;
  const possibleAmenities = {
    oceanfront: true,
    riverfront: true,
    pool: true,
    hottub: true,
    elevator: true,
    dock: true,
    riverfrontRecreationalArea: true,
    resortAccess: true,
    swimSpa: true,
  };
  const transformedPropertiesList =
    // properties &&
    // properties
    //   .filter(
    //     property =>
    //       property.type !== 'COMBO' &&
    //       (property.sitePage || {}).status === 'PUBLISHED'
    //   )
    properties.map(property => {
      const amenityList = property.amenities.reduce((acc, amenity) => {
        Object.keys(possibleAmenities).forEach(possibleAmenity => {
          if (!acc[possibleAmenity]) {
            if (amenity.key === 'swimSpa') {
              acc['pool'] = 1;
            } else if (amenity.features) {
              acc[amenity.key] = 1;
            } else {
              acc[possibleAmenity] = amenity.key === possibleAmenity ? 2 : 0;
            }
          }
        });

        return acc;
      }, {});
      return {
        ...property,
        ...amenityList,
        mistingSystemLabel: property.mistingSystem?.label,
      };
    });
  const getOrderedProperties = () => {
    if (sortBy) {
      return reverse
        ? transformedPropertiesList
            .concat()
            .sort(orderBy(sortBy))
            .reverse()
        : transformedPropertiesList.concat().sort(orderBy(sortBy));
    }
    return transformedPropertiesList;
  };

  const orderedProperties = getOrderedProperties();

  const getSlug = property => {
    if (property.type === 'COMBO') {
      return `large-groups#${property.key}`;
    } else return get(property, 'sitePage.slug') || '';
  };
  const ArrowDisplay = ({ id }) => {
    // const arrowRef = useRef(`${id}`);

    if (sortBy === id) {
      return (
        <div /*ref={arrowRef}*/ id={id} className="arrow-parent">
          <img width="8" className="arrow" src={reverse ? ArrowUp : Arrow} />
        </div>
      );
    }
    return null;
  };
  const getPlatformLinks = propertyListings => {
    // console.log('propertyListings', propertyListings);
    // const hasTopRow = propertyListings.find(
    //   listing =>
    //     listing.platform.key === 'airbnb' || listing.platform.key === 'homeaway'
    // );
    // const hasBottomRow = propertyListings.find(
    //   listing =>
    //     listing.platform.key === 'tripadvisor' ||
    //     listing.platform.key === 'bookingcom'
    // );
    // const TopRow = () => {
    //   return propertyListings.map((property, index) => {
    //     const key = get(property, 'platform.key') || {};
    //     const propertyUrl = get(property, 'propertyUrl') || {};
    //     switch (key) {
    //       case 'homeaway':
    //         return (
    //           <a
    //             key={index}
    //             target="_blank"
    //             href={propertyUrl ? propertyUrl : ''}
    //           >
    //             <img className="mx-1" src={VRBO} />
    //           </a>
    //         );
    //       case 'airbnb':
    //         return (
    //           <a
    //             key={index}
    //             target="_blank"
    //             href={propertyUrl ? propertyUrl : ''}
    //           >
    //             <img className="mx-1" src={AirBnb} />
    //           </a>
    //         );

    //       default:
    //         return null;
    //     }
    //   });
    // };
    // const getPropertyListingByPlatformKey = platformKey => {
    //   return propertyListings.find(propertyListing => {
    //     console.log('propertyListing', propertyListing);
    //     return propertyListing.platform.key === platformKey;
    //   });
    // };
    // const BottomRow = () => {
    //   return propertyListings.map((property, index) => {
    //     const key = get(property, 'platform.key') || {};
    //     const propertyUrl = get(property, 'propertyUrl') || {};
    //     switch (key) {
    //       case 'tripadvisor':
    //         return (
    //           <a
    //             key={index}
    //             target="_blank"
    //             href={propertyUrl ? propertyUrl : ''}
    //           >
    //             <img className="mx-1" src={TripAdvisor} />
    //           </a>
    //         );
    //       case 'bookingcom':
    //         return (
    //           <a
    //             key={index}
    //             target="_blank"
    //             href={propertyUrl ? propertyUrl : ''}
    //           >
    //             <img className="mx-1" src={Booking} />
    //           </a>
    //         );
    //       default:
    //         return null;
    //     }
    //   });
    // };

    const getPropertyListingMap = () => {
      return propertyListings.reduce((acc, propertyListing) => {
        acc[propertyListing.platform.key] = propertyListing;

        return acc;
      }, {});
    };
    const propertyListingMap = getPropertyListingMap();
    return (
      <div className="row no-gutters" style={{ width: '80px' }}>
        <a
          target="_blank"
          className="col mb-2"
          title="VRBO/Homeaway"
          href={
            get(propertyListingMap, 'homeaway.propertyUrl')
              ? get(propertyListingMap, 'homeaway.propertyUrl')
              : ''
          }
          onClick={event => {
            if (!get(propertyListingMap, 'homeaway.propertyUrl')) {
              event.preventDefault();
            }
          }}
          style={
            !get(propertyListingMap, 'homeaway.propertyUrl')
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.4,
                }
              : {}
          }
        >
          <img className="mx-1" src={VRBO} />
        </a>

        <a
          target="_blank"
          className="col  mb-2"
          title="Airbnb"
          href={
            get(propertyListingMap, 'airbnb.propertyUrl')
              ? get(propertyListingMap, 'airbnb.propertyUrl')
              : ''
          }
          onClick={event => {
            if (!get(propertyListingMap, 'airbnb.propertyUrl')) {
              event.preventDefault();
            }
          }}
          style={
            !get(propertyListingMap, 'airbnb.propertyUrl')
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.4,
                }
              : {}
          }
        >
          <img className="mx-1" src={AirBnb} />
        </a>
        <div className="w-100"></div>
        <a
          target="_blank"
          className="col"
          title="Booking.com"
          href={
            get(propertyListingMap, 'bookingcom.propertyUrl')
              ? get(propertyListingMap, 'bookingcom.propertyUrl')
              : ''
          }
          onClick={event => {
            if (!get(propertyListingMap, 'bookingcom.propertyUrl')) {
              event.preventDefault();
            }
          }}
          style={
            !get(propertyListingMap, 'bookingcom.propertyUrl')
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.4,
                }
              : {}
          }
        >
          <img className="mx-1" src={Booking} />
        </a>
        <a
          target="_blank"
          className="col"
          title="Tripadvisor"
          href={
            get(propertyListingMap, 'tripadvisor.propertyUrl')
              ? get(propertyListingMap, 'tripadvisor.propertyUrl')
              : ''
          }
          onClick={event => {
            if (!get(propertyListingMap, 'tripadvisor.propertyUrl')) {
              event.preventDefault();
            }
          }}
          style={
            !get(propertyListingMap, 'tripadvisor.propertyUrl')
              ? {
                  filter: 'grayscale(1)',
                  opacity: 0.4,
                }
              : {}
          }
        >
          <img className="mx-1" src={TripAdvisor} />
        </a>
        {/* {hasTopRow && (
          <div
            className={`d-flex justify-content-between ${hasBottomRow &&
              'mb-2'}`}
          >
            <TopRow />
          </div>
        )}
        {hasBottomRow && (
          <div className="d-flex justify-content-between mb-2">
            <BottomRow />
          </div>
        )} */}
      </div>
    );
  };
  const getNestedTableTDWidth = (id, defaultWidth) => {
    if (sortBy === id) {
      return `${defaultWidth + 12}px`;
    }
    return `${defaultWidth}px`;
  };
  const getHeaderThWidth = (id, defaultWidth) => {
    if (sortBy === id) {
      return `${defaultWidth + 12}px`;
    }
    return `${defaultWidth}px`;
  };

  const getThStyles = (width, name) => {
    const minOrMaxWidth = {};
    if (isFirefox) {
      minOrMaxWidth.maxWidth = name ? getHeaderThWidth(name, width) : width;
    } else {
      minOrMaxWidth.minWidth = name ? getHeaderThWidth(name, width) : width;
    }
    return minOrMaxWidth;
  };
  return (
    <>
      <BiaxialGrid
        topLeftCornerContent={
          <div className="align-middle text-center font-weight-bold first-column">
            Our Houses
          </div>
        }
        headerRow={
          <table
            id="table-header-row"
            className="table property-overview-table mb-0 table-bordered"
          >
            <thead>
              <tr className="header-top-row">
                {/* <th
                  className="align-middle text-center font-weight-bold first-column"
                  rowSpan="2"
                >
                  Our Houses
                  
                </th> */}
                <th
                  className="align-middle text-center font-weight-bold second-column"
                  rowSpan="2"
                  id="travelers"
                  // style={{
                  //   minWidth: getHeaderThWidth('sleeps', 78),
                  // }}
                  style={{
                    ...getThStyles(78, 'sleeps'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="sleeps" />
                    <div
                      className="header-text clickable"
                      onClick={() => {
                        setOrderByData('sleeps');
                      }}
                    >
                      <span className="d-block">Max</span>{' '}
                      <span>Travelers</span>
                    </div>
                    {/* <img width="8" src={ArrowUp} /> */}
                  </div>
                </th>
                <th
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('maxAdults', 61),
                  // }}
                  style={{
                    ...getThStyles(61, 'maxAdults'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="maxAdults" />
                    <div
                      className="header-text clickable"
                      onClick={() => {
                        setOrderByData('maxAdults');
                      }}
                    >
                      <span className="d-block">Max</span> <span>Adults</span>
                    </div>
                  </div>
                </th>
                <th
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('maxAdultComfort', 71),
                  // }}
                  style={{
                    ...getThStyles(71, 'maxAdultComfort'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="maxAdultComfort" />
                    <div
                      className="header-text clickable"
                      onClick={() => {
                        setOrderByData('maxAdultComfort');
                      }}
                    >
                      <span className="d-block">Adults</span>{' '}
                      <span>Comfort</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('sleepsInBed', 63),
                  // }}
                  style={{
                    ...getThStyles(63, 'sleepsInBed'),
                  }}
                >
                  <div>
                    <div className="d-flex justify-content-center">
                      <ArrowDisplay id="sleepsInBed" />
                      <div
                        className="header-text clickable"
                        onClick={() => {
                          setOrderByData('sleepsInBed');
                        }}
                      >
                        <span className="d-block">Sleeps</span>{' '}
                        <span>In Bed</span>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold header-top-cell pl-2 py-2"
                  colSpan="2"
                >
                  Bed Rooms
                  {/* <div className="header-top-cell p-1">Bed Rooms</div> */}
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold header-top-cell pl-2 py-2"
                  colSpan="2"
                >
                  <span className="d-block">Bath</span> <span>Rooms</span>
                  {/* <div className="header-top-cell p-1">Bath Rooms</div> */}
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold header-top-cell pl-2 py-2"
                  colSpan="2"
                >
                  Vehicles
                  {/* <div className="header-top-cell p-1">Vehicles</div> */}
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('sqft', 66),
                  // }}
                  style={{
                    ...getThStyles(66, 'sqft'),
                  }}
                >
                  <div
                    onClick={() => setOrderByData('sqft')}
                    className="d-flex justify-content-center clickable"
                  >
                    <ArrowDisplay id="sqft" />

                    <div className="header-text clickable">
                      <span className="d-block">Livable</span>{' '}
                      <span>Sq Ft</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth(60, 'oceanfront'),
                  // }}
                  style={{
                    ...getThStyles(60, 'oceanfront'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="oceanfront" />
                    <div
                      className="header-text clickable"
                      onClick={() => setOrderByData('oceanfront')}
                    >
                      <span className="d-block">Ocean</span> <span>Front</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('riverfront', 53),
                  // }}
                  style={{
                    ...getThStyles(53, 'riverfront'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="riverfront" />
                    <div
                      className="header-text clickable"
                      onClick={() => setOrderByData('riverfront')}
                    >
                      <span className="d-block">River</span> <span>Front</span>
                    </div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('pool', 48),
                  // }}
                  style={{
                    ...getThStyles(48, 'pool'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="pool" />
                    <span
                      className="header-text clickable"
                      onClick={() => setOrderByData('pool')}
                    >
                      Pool
                    </span>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('hottub', 42),
                  // }}
                  style={{
                    ...getThStyles(42, 'hottub'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="hottub" />

                    <div
                      className="header-text clickable"
                      onClick={() => setOrderByData('hottub')}
                    >
                      <span className="d-block">Hot</span> <span>Tub</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('elevator', 49),
                  // }}
                  style={{
                    ...getThStyles(49, 'elevator'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="elevator" />
                    <span
                      className="header-text clickable"
                      onClick={() => setOrderByData('elevator')}
                    >
                      Elev.
                    </span>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('dock', 52),
                  // }}
                  style={{
                    ...getThStyles(52, 'dock'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="dock" />
                    <span
                      className="header-text clickable"
                      onClick={() => setOrderByData('dock')}
                    >
                      Dock
                    </span>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth(
                  //     'riverfrontRecreationalArea',
                  //     53
                  //   ),
                  // }}
                  style={{
                    ...getThStyles(53, 'riverfrontRecreationalArea'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="riverfrontRecreationalArea" />

                    <div
                      className="header-text clickable"
                      onClick={() =>
                        setOrderByData('riverfrontRecreationalArea')
                      }
                    >
                      <span className="d-block">River</span>{' '}
                      <span className="d-block">Rec</span>
                      <span>Area</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: getHeaderThWidth('riverfront', 53),
                  // }}
                  style={{
                    ...getThStyles(67, 'mistingSystemLabel'),
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <ArrowDisplay id="mistingSystemLabel" />
                    <div
                      className="header-text clickable"
                      onClick={() => setOrderByData('mistingSystemLabel')}
                    >
                      <span className="d-block">Misting</span>{' '}
                      <span>System</span>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  style={{ minWidth: '89px' }}
                >
                  VRPs
                </th>
                <th
                  // style={{ minWidth: '78px' }}
                  scope="col"
                  className="align-middle text-center font-weight-bold"
                  rowSpan="2"
                  // style={{
                  //   maxWidth: 76,
                  // }}
                  // style={{
                  //   ...getThStyles(76),
                  // }}
                  style={{
                    minWidth: isFirefox ? '74px' : '77px',
                  }}
                >
                  Action
                </th>
                <th
                  scope="col"
                  rowSpan="2"
                  className="p-0"
                  style={{
                    minWidth: isFirefox ? '10px' : '3px',
                  }}
                  id="date-bar-horizontal-spacer"
                ></th>
              </tr>
              <tr className="header-bottom-row">
                <th
                  className="header-bottom-cell py-1 align-middle"
                  // style={{
                  //   maxWidth: getHeaderThWidth('bedroomsPrivate', 64),
                  // }}
                  style={{
                    ...getThStyles(64, 'bedroomsPrivate'),
                  }}
                >
                  {' '}
                  <div
                    className="header-text clickable align-middle d-flex justify-content-center"
                    onClick={() => {
                      setOrderByData('bedroomsPrivate');
                    }}
                  >
                    <ArrowDisplay id="bedroomsPrivate" />
                    <span className="header-text">Private</span>
                  </div>
                </th>
                <th
                  className="header-bottom-cell py-1 align-middle"
                  // style={{
                  //   maxWidth: getHeaderThWidth('bedroomsSemiPrivate', 64),
                  // }}
                  style={{
                    ...getThStyles(64, 'bedroomsSemiPrivate'),
                  }}
                >
                  <div
                    onClick={() => {
                      setOrderByData('bedroomsSemiPrivate');
                    }}
                    className="d-flex justify-content-center"
                  >
                    <ArrowDisplay id="bedroomsSemiPrivate" />

                    <div className="header-text text-center clickable">
                      <span className="d-block">Semi</span> <span>Private</span>
                    </div>
                  </div>
                </th>
                <th
                  className="header-bottom-cell py-1 align-middle"
                  // style={{
                  //   maxWidth: getHeaderThWidth('bathroomsFull', 42),
                  // }}
                  style={{
                    ...getThStyles(42, 'bathroomsFull'),
                  }}
                >
                  {' '}
                  <div
                    onClick={() => {
                      setOrderByData('bathroomsFull');
                    }}
                    className="d-flex justify-content-center clickable"
                  >
                    <ArrowDisplay id="bathroomsFull" />
                    <span className="header-text clickable">Full</span>
                  </div>
                </th>
                <th
                  className="header-bottom-cell py-1 align-middle"
                  // style={{
                  //   maxWidth: getHeaderThWidth('bathroomsHalf', 44),
                  // }}
                  style={{
                    ...getThStyles(44, 'bathroomsHalf'),
                  }}
                >
                  {' '}
                  <div
                    onClick={() => {
                      setOrderByData('bathroomsHalf');
                    }}
                    className="d-flex justify-content-center clickable"
                  >
                    <ArrowDisplay id="bathroomsHalf" />
                    <span className="header-text clickable">Half</span>
                  </div>
                </th>
                <th
                  className="header-bottom-cell"
                  // style={{
                  //   maxWidth: getHeaderThWidth('parkingUncovered', 90),
                  // }}
                  style={{
                    ...getThStyles(90, 'parkingUncovered'),
                  }}
                >
                  <div
                    onClick={() => {
                      setOrderByData('parkingUncovered');
                    }}
                    className="d-flex justify-content-center clickable"
                  >
                    <ArrowDisplay id="parkingUncovered" />
                    <span className="header-text clickable">Uncovered</span>
                  </div>
                </th>
                <th
                  className="header-bottom-cell"
                  // style={{
                  //   maxWidth: getHeaderThWidth('parkingCovered', 74),
                  // }}
                  style={{
                    ...getThStyles(74, 'parkingCovered'),
                  }}
                >
                  {' '}
                  <div
                    onClick={() => {
                      setOrderByData('parkingCovered');
                    }}
                    className="d-flex justify-content-center clickable"
                  >
                    <ArrowDisplay id="parkingCovered" />
                    <span className="header-text clickable">Covered</span>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        }
        firstColumn={
          <table className="table property-overview-table">
            <tbody>
              {orderedProperties &&
                orderedProperties.map((property, index) => {
                  const foundChannel =
                    (property.propertyListings &&
                      property.propertyListings.find(
                        channel => channel.platform.key === 'homeaway'
                      )) ||
                    {};

                  const homeAwayUrl = foundChannel.propertyUrl
                    ? foundChannel.propertyUrl
                    : null;
                  const featuredFiles =
                    get(property, 'sitePage.featuredFiles') || [];
                  const youTubeFile = featuredFiles.find(
                    featuredFile =>
                      (featuredFile.file || {}).mimetype === 'video/youtube'
                  );
                  const youTubeLink = youTubeFile
                    ? youTubeFile.file.fullUrl
                    : null;
                  const amenities = get(property, 'amenities') || [];
                  //   const slug = get(property, 'sitePage.slug') || '';
                  const slug = getSlug(property);
                  const matterportUrl =
                    get(property, 'virtualTours[0].url') || '';

                  const getPoolType = () => {
                    const amenityKeys =
                      amenities.map(amenity => amenity.key) || [];
                    const isXanadu = () => {
                      return (property.childProperties || []).find(
                        childProperty => childProperty.key === '871'
                      );
                    };
                    return (
                      <div className="d-flex flex-column  font-weight-bold">
                        {amenityKeys.includes('swimSpa') && (
                          <>
                            <span className="swim-spa">Swim</span>{' '}
                            <span className="swim-spa">Spa</span>
                          </>
                        )}
                        {amenityKeys.includes('resortAccess') &&
                          (property.type === 'STANDARD' ||
                            (property.type === 'COMBO' &&
                              !isXanadu() &&
                              !amenityKeys.includes('pool'))) && (
                            <>
                              <img src={Star} title="Resort Access" />
                            </>
                          )}
                        {amenityKeys.includes('pool') && (
                          <>
                            <img src={AmenityYes} title="Pool" />
                          </>
                        )}
                        {amenityKeys.length === 0 && (
                          <img src={AmenityNo} title="None" />
                        )}
                      </div>
                    );
                  };
                  const thumbnailUrl =
                    get(
                      property,
                      'sitePage.featuredImage.file.version.fullUrl'
                    ) || 'https://via.placeholder.com/466x349';

                  //   if (index < 4) {
                  return (
                    <tr key={property?.key}>
                      <th scope="row" className="align-middle first-column">
                        <div className="d-flex">
                          <div className="mr-2 d-none d-lg-flex">
                            <img
                              className="property-image"
                              height="50"
                              width="67"
                              src={thumbnailUrl}
                            />
                          </div>
                          <div className="d-flex flex-column w-100 text-xs align-items-between">
                            <div className="mb-2 d-flex " >
                              <span>{property.label}</span>
                              <span className="d-none d-lg-flex mx-1">-</span>
                              <span className="d-none d-lg-flex  w-100 justify-content-between text-nowrap">
                                <div>{property.name.substr(0,15)}</div>
                                <div>{property.masterPlatformListing?.payoutType == 'EARLY' && <><img title="Early Payout" src={earlyPayout} className={`mr-1`} /></>}</div>
                              </span>
                            </div>

                            <div className="d-flex">
                              <a
                                target="_blank"
                                data-url={`https://www.beachhousesinparadise.com/${slug}`}
                                href={`https://www.beachhousesinparadise.com/${slug}`}
                              >
                                <img className="mr-1 mr-lg-1plus" src={BHIP} />
                              </a>
                              {property.floorPlan?.fileInstance && (
                                <a
                                  target="_blank"
                                  href={`${property.floorPlan.fileInstance.file.fullUrl}`}
                                >
                                  <img
                                    className="mx-1 mx-lg-1plus"
                                    src={FloorPlan}
                                  />
                                </a>
                              )}
                              <a
                                target="_blank"
                                data-url={youTubeLink}
                                href={youTubeLink}
                              >
                                <img
                                  className="mx-1 mx-lg-1plus"
                                  width="20"
                                  src={YouTube}
                                />
                              </a>
                              <a
                                target="_blank"
                                data-url={matterportUrl}
                                href={matterportUrl}
                              >
                                <img
                                  className="ml-1 ml-lg-1plus"
                                  src={MatterPort}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        }
        innerContent={
          <table className="table property-overview-table inner-table">
            <tbody>
              {orderedProperties &&
                orderedProperties.map((property, index) => {
                  const foundChannel =
                    (property.propertyListings &&
                      property.propertyListings.find(
                        channel => channel.platform.key === 'homeaway'
                      )) ||
                    {};
                  const homeAwayUrl = foundChannel.propertyUrl
                    ? foundChannel.propertyUrl
                    : null;
                  const amenities = get(property, 'amenities') || [];
                  const getPoolType = () => {
                    const amenityKeys =
                      amenities.map(amenity => amenity.key) || [];
                    const isXanadu = () => {
                      return (property.childProperties || []).find(
                        childProperty => childProperty.key === '871'
                      );
                    };
                    return (
                      <>
                        {/* <div className="d-flex flex-column  font-weight-bold"> */}
                        {amenityKeys.includes('swimSpa') && (
                          <div className="d-flex flex-column  font-weight-bold">
                            <span className="swim-spa font-weight-bold">
                              Swim
                            </span>{' '}
                            <span className="swim-spa font-weight-bold">
                              Spa
                            </span>
                          </div>
                        )}
                        {amenityKeys.includes('resortAccess') &&
                          (property.type === 'STANDARD' ||
                            (property.type === 'COMBO' &&
                              !isXanadu() &&
                              !amenityKeys.includes('pool'))) && (
                            <>
                              <img
                                src={Star}
                                style={{ maxWidth: '25px' }}
                                title="Resort Access"
                              />
                            </>
                          )}
                        {amenityKeys.includes('pool') && (
                          <>
                            <img
                              src={AmenityYes}
                              style={{ maxWidth: '25px' }}
                              title="Pool"
                            />
                          </>
                        )}
                        {amenityKeys.length === 0 && (
                          <img
                            src={AmenityNo}
                            style={{ maxWidth: '25px' }}
                            title="None"
                          />
                        )}
                        {/* </div> */}
                      </>
                    );
                  };
                  return (
                    <tr key={`nested_table_${property?.key}`}>
                      <td
                        className="text-center align-middle second-column"
                        style={{
                          minWidth: getNestedTableTDWidth('sleeps', 78),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                      >
                        {property.sleeps || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('maxAdults', 61),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.maxAdults || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'maxAdultComfort',
                            71
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.maxAdultComfort || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('sleepsInBed', 63),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.sleepsInBed}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'bedroomsPrivate',
                            64
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.bedroomsPrivate || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'bedroomsSemiPrivate',
                            64
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.bedroomsSemiPrivate || 0}
                      </td>

                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('bathroomsFull', 42),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.bathroomsFull || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('bathroomsHalf', 44),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.bathroomsHalf || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'parkingUncovered',
                            90
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle "
                      >
                        {property.parkingUncovered || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('parkingCovered', 74),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.parkingCovered || 0}
                      </td>

                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('sqft', 66),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.sqft || 0}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('oceanfront', 60),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {amenities.find(
                          amenity => amenity.key === 'oceanfront'
                        ) ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('riverfront', 53),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {' '}
                        {amenities.find(
                          amenity => amenity.key === 'riverfront'
                        ) ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('pool', 48),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {getPoolType()}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('hottub', 42),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {' '}
                        {amenities.find(amenity => amenity.key === 'hottub') ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('elevator', 49),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {' '}
                        {amenities.find(
                          amenity => amenity.key === 'elevator'
                        ) ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth('dock', 52),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {' '}
                        {amenities.find(amenity => amenity.key === 'dock') ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'riverfrontRecreationalArea',
                            53
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {' '}
                        {amenities.find(
                          amenity =>
                            amenity.key === 'riverfrontRecreationalArea'
                        ) ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: getNestedTableTDWidth(
                            'mistingSystemLabel',
                            67
                          ),
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        {property.mistingSystemLabel ? (
                          <img src={AmenityYes} />
                        ) : (
                          <img src={AmenityNo} />
                        )}
                      </td>
                      <td
                        style={{
                          minWidth: '89px',
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle p-1"
                      >
                        {getPlatformLinks(property.propertyListings || [])}
                      </td>
                      <td
                        style={{
                          minWidth: '76px',
                          height: isFirefox ? '75.86px' : '75.38px',
                        }}
                        className="text-center align-middle"
                      >
                        <div className="d-flex flex-column align-items-center">
                          {homeAwayUrl && (
                            <a
                              target="_blank"
                              href={homeAwayUrl}
                              className={` ${
                                homeAwayUrl ? '' : 'disabled'
                              } btn btn-primary btn-sm mb-2 action-button`}
                            >
                              Book
                            </a>
                          )}
                          <button
                            onClick={() => {
                              setQuoteModalIsOpen(true);
                              setTargetProperty(property);
                            }}
                            className="btn btn-outline-primary btn-sm action-button"
                          >
                            Quote
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        }
      />

      <Modal
        isOpen={sleepsModalIsOpen}
        onClose={() => setSleepsModalIsOpen(false)}
        disableForceFocus={true}
        title={targetProperty && targetProperty.name}
      >
        sleeps data
      </Modal>
      <Modal
        isOpen={quoteModalIsOpen}
        onClose={() => setQuoteModalIsOpen(false)}
        disableForceFocus={true}
        title={targetProperty && targetProperty.name}
        styleType="large"
      >
        <QuoteGenerator propertyId={targetProperty && targetProperty.id} />
      </Modal>
    </>
  );
};

export default OverviewTable;
